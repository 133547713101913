import clsx from 'clsx';
import { Key } from 'react';

import {
  Card,
  Content,
  IUseTransitionElementBodyArgs,
  Text,
  useTransitionElementBody,
} from 'shared/ui/layout';

import { ISelectPopupOptionProps, SelectPopupOption } from '../select-popup-option';

import { IUseSelectPopupBodyArgs, useSelectPopupBody } from './select-popup.hook';

import styles from '../select-popup.module.scss';

export interface ISelectPopupBodyDefaultProps<T extends SelectableOptionType>
  extends Omit<ISelectPopupOptionProps<T>, 'selected' | 'option'>,
    IUseSelectPopupBodyArgs<T> {
  options: T[];
  value?: SelectableValueType<T>;
  valueProperty: AffordableSelectorKey<T>;
  title?: string;
}

interface ISelectPopupBodyProps<T extends SelectableOptionType>
  extends IUseTransitionElementBodyArgs,
    ISelectPopupBodyDefaultProps<T> {}

export function SelectPopupBody<T extends SelectableOptionType>({
  options,
  optionClassName,
  value,
  title,
  valueProperty,
  labelProperty,
  noExternal,
  onSelect,
  onClose,
}: ISelectPopupBodyProps<T>) {
  const { wrapperRef, contentRef } = useTransitionElementBody({ onClose, noExternal });
  const { selectedValue, onSelect: onSelectOption } = useSelectPopupBody({
    value,
    valueProperty,
    onSelect,
  });
  return (
    <div className={styles.wrapper}>
      <div
        ref={wrapperRef}
        className={clsx('modal-container-wrapper', styles['container-wrapper'])}
      >
        <div className={styles.container}>
          <Content type='modal-form-wide' ref={contentRef} className={clsx(styles.content)}>
            <Card radius='small' padding='medium'>
              <Content padding='small'>
                <button
                  type='button'
                  className='text-sm text-primary hover:text-primary-dark mb-4 block'
                  onClick={onClose}
                >
                  Назад
                </button>
                {title && (
                  <Text type='block-title' className='mb-21px'>
                    {title}
                  </Text>
                )}
                {options.map((option) => (
                  <SelectPopupOption
                    key={option[valueProperty] as Key}
                    option={option}
                    optionClassName={optionClassName}
                    selected={selectedValue === option[valueProperty]}
                    labelProperty={labelProperty}
                    onSelect={onSelectOption}
                  />
                ))}
              </Content>
            </Card>
          </Content>
        </div>
      </div>
      <div className={styles.overlay} />
    </div>
  );
}
