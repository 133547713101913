import { FC, ReactNode } from 'react';

import { ICalendarDaySlotRes } from 'entities/training/api';
import { CoachTextElement, SimpleUserElement } from 'entities/user';

import { ISimpleUserResponse } from 'shared/api/user';
import { DateType, DateUtil } from 'shared/helpers/date';
import { DesktopView, MobileView, Text } from 'shared/ui/layout';

type TrainingInfoRowPropsType = {
  action?: ReactNode;
  date: DateType;
  time: number;
  onClick?: (date: DateType, time: number) => void;
  className?: string;
  breakHour?: boolean;
} & (
  | { info: ICalendarDaySlotRes; defaultCoach?: ISimpleUserResponse }
  | { info?: never; defaultCoach?: ISimpleUserResponse }
);

export const TrainingInfoRow: FC<TrainingInfoRowPropsType> = ({
  action,
  date,
  time,
  info,
  defaultCoach,
  breakHour,
  className,
  onClick,
}) => {
  const coach = info ? info.coach : defaultCoach;

  let rowInfo: Unset<JSX.Element>;
  if (breakHour) {
    rowInfo = <>Студия не работает</>;
  } else if (info) {
    if (info.status === 'cancelled') {
      rowInfo = <>Отменено</>;
    } else {
      rowInfo = (
        <div className='flex items-center gap-30px'>
          {info.clients.map((client) => (
            <SimpleUserElement key={client.id} data={client} />
          ))}
        </div>
      );
    }
  }

  const handleClick =
    onClick && ((info && info.status !== 'cancelled') || (!info && !breakHour))
      ? () => {
          onClick(date, time);
        }
      : undefined;

  return (
    <tr className={className}>
      {action && <td className='cursor-pointer'>{action}</td>}
      <td onClick={handleClick} className={handleClick ? 'cursor-pointer' : ''}>
        <MobileView>
          {coach && (
            <CoachTextElement id={coach.id} name={coach.name} color={coach.color} firstWord />
          )}
        </MobileView>
        <Text>{DateUtil.formatNumberHour(time)}</Text>
      </td>
      <DesktopView>
        <td onClick={handleClick} className={handleClick ? 'cursor-pointer' : ''}>
          {coach && <CoachTextElement id={coach.id} name={coach.name} color={coach.color} />}
        </td>
      </DesktopView>
      <td onClick={handleClick} className={handleClick ? 'cursor-pointer' : ''}>
        {rowInfo}
      </td>
    </tr>
  );
};
