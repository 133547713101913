import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { UserHeaderInfo } from 'widgets/user/user-header-info';

import { useSessionMainPageRoute } from 'entities/session';

import { useScreenLayoutDetect } from 'shared/hooks';
import iconBack from 'shared/icons/icon-back.svg';
import logo from 'shared/icons/logo.svg';

import triangleLeft from './icons/triangle-left.svg';
import triangleRight from './icons/triangle-right.svg';

import styles from './header.module.scss';

type HeaderPropsType = {
  wrapperClassName?: string;
  className?: string;
  pageHeader?: boolean;
  mobileMode?: 'dark' | 'default';
  profileClickable?: boolean;
  actionContainerId?: string;
} & (
  | {
      onBack: () => void;
      backText?: string;
    }
  | { onBack?: never; backText?: never }
);
const HeaderComponent: FC<HeaderPropsType> = ({
  wrapperClassName,
  className,
  pageHeader,
  mobileMode = 'dark',
  actionContainerId,
  onBack,
  backText = 'Назад',
  profileClickable = true,
}) => {
  const { isMobileLayout } = useScreenLayoutDetect();
  const mainRoute = useSessionMainPageRoute();
  return (
    <>
      <div className={clsx(styles.wrapper, pageHeader && 'header-desktop', wrapperClassName)}>
        <div className={styles['container-wrapper']}>
          <div
            className={clsx(
              styles.container,
              mobileMode === 'dark' && styles['container_dark-on-mobile'],
              className,
            )}
          >
            <div className={styles['action-wrapper']}>
              {onBack && (
                <button onClick={onBack} className={styles['action-container']}>
                  <img width={8} height={15} src={iconBack} alt='' />
                  {backText}
                </button>
              )}
              {actionContainerId && <div id={actionContainerId} />}
            </div>
            <Link to={mainRoute}>
              <img src={logo} alt='' />
            </Link>
            <div className={styles['action-wrapper']}>
              <UserHeaderInfo clickable={profileClickable} />
            </div>
          </div>
          {isMobileLayout && mobileMode === 'dark' && (
            <>
              <img src={triangleLeft} className={styles['triangle-left']} alt='' />
              <img src={triangleRight} className={styles['triangle-right']} alt='' />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const Header = observer(HeaderComponent);
