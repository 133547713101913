/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';

import { SsError } from './axios.error';
import { axiosInstance } from './axios.instance';
import {
  AxiosInstanceErrorResponseType,
  AxiosInstanceResponseType,
  AxiosInstanceSuccessResponseType,
  IAxiosInstanceRequestConfig,
} from './axios.type';

type IAxiosApiRequestConfig = IAxiosInstanceRequestConfig &
  Pick<IAxiosInstanceRequestConfig, 'cancelToken' | 'onUploadProgress' | 'params' | 'headers'>;

function getSuccessResponse<T>(data: T): AxiosInstanceSuccessResponseType<T> {
  return { success: true, data };
}
function getErrorResponse(error: SsError): AxiosInstanceErrorResponseType {
  return { success: false, code: error.code, message: error.message, details: error.details };
}

async function performWrappedPromise<T = unknown>(
  promise: Promise<AxiosResponse<any, any>>,
): Promise<AxiosInstanceResponseType<T>> {
  try {
    const res = await promise;
    return getSuccessResponse(res.data);
  } catch (err) {
    return getErrorResponse(err as SsError);
  }
}

async function performPromise<T = unknown>(promise: Promise<AxiosResponse<any, any>>): Promise<T> {
  const res = await promise;
  return res.data as T;
}

export function createApiInstance(baseUrl: string) {
  function getModifiedUrl(url: string, { service }: IAxiosApiRequestConfig): string {
    return `${baseUrl}${service ? `/${service}` : ''}${url}`;
  }
  return {
    get<T = unknown>(url: string, config: IAxiosApiRequestConfig = {}) {
      return performPromise<T>(axiosInstance.get(getModifiedUrl(url, config), config));
    },
    post<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performPromise<T>(axiosInstance.post(getModifiedUrl(url, config), body, config));
    },
    put<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performPromise<T>(axiosInstance.put(getModifiedUrl(url, config), body, config));
    },
    patch<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performPromise<T>(axiosInstance.patch(getModifiedUrl(url, config), body, config));
    },
    delete<T = unknown>(url: string, config: IAxiosApiRequestConfig = {}) {
      return performPromise<T>(axiosInstance.delete(getModifiedUrl(url, config), config));
    },
    getWrap<T = unknown>(url: string, config: IAxiosApiRequestConfig = {}) {
      return performWrappedPromise<T>(axiosInstance.get(getModifiedUrl(url, config), config));
    },
    postWrap<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performWrappedPromise<T>(
        axiosInstance.post(getModifiedUrl(url, config), body, config),
      );
    },
    putWrap<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performWrappedPromise<T>(axiosInstance.put(getModifiedUrl(url, config), body, config));
    },
    patchWrap<T = any, U = any>(url: string, body: U, config: IAxiosApiRequestConfig = {}) {
      return performWrappedPromise<T>(
        axiosInstance.patch(getModifiedUrl(url, config), body, config),
      );
    },
    deleteWrap<T = unknown>(url: string, config: IAxiosApiRequestConfig = {}) {
      return performWrappedPromise<T>(axiosInstance.delete(getModifiedUrl(url, config), config));
    },
  };
}

export const baseApi = createApiInstance('');
