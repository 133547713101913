import { FC } from 'react';

import { Selector } from 'shared/ui/controls';

import { IUserSexEditorArgs, useUserSexEditor, userSexOptions } from './user-sex-editor.hook';

interface IUserSexEditorProps extends IUserSexEditorArgs {
  className?: string;
}
export const UserSexEditor: FC<IUserSexEditorProps> = ({ className, ...args }) => {
  const { sex, onSexChange } = useUserSexEditor(args);
  return (
    <Selector
      label='Пол'
      wrapperClassName={className}
      options={userSexOptions}
      selectedValue={sex}
      valueProperty='value'
      labelProperty='label'
      onSelect={onSexChange}
    />
  );
};
