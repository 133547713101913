import { useCallback, useState } from 'react';

export interface IUseSelectPopupArgs<T> {
  onSelect: (value: T) => void;
}
export function useSelectPopup<T>({ onSelect }: IUseSelectPopupArgs<T>) {
  const [visible, setVisible] = useState(false);

  const handleOpen = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const handleSelect = (value: T) => {
    onSelect(value);
    setVisible(false);
  };

  return {
    visible,
    onOpen: handleOpen,
    onClose: handleClose,
    onSelect: handleSelect,
  };
}
