import { toast } from 'react-toastify';

import { IResponseError } from 'shared/api/axios';

export function showSuccessToaster(message: string) {
  toast(message, {
    hideProgressBar: true,
    autoClose: 1000,
    type: 'success',
  });
}
export function showErrorToaster(error: string | undefined | IResponseError) {
  let message: string;
  if (typeof error === 'string' || typeof error === 'undefined') {
    message = error || 'Произошла ошибка';
  } else {
    message = error.message || 'Произошла ошибка';
  }
  toast(message || 'Error', {
    hideProgressBar: true,
    autoClose: 2000,
    type: 'error',
  });
}
