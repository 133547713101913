import { IStatBaseRes } from 'entities/stat/api/payload/stat-base-res';
import { getBaseStat } from 'entities/stat/api/service/get-base-stat';

import { StatInfoVM } from './stat-info.vm';

export class StatBaseInfoVM extends StatInfoVM<IStatBaseRes> {
  protected getLoader(): Promise<IStatBaseRes> {
    return getBaseStat();
  }
}
