import Cookies, { CookieAttributes } from 'js-cookie';

type Attributes = CookieAttributes;

export function getCookie(name: string) {
  return Cookies.get(name);
}

export function setCookie(name: string, value: string, options?: Attributes) {
  Cookies.set(name, value, options);
}

export function removeCookie(name: string, options?: Attributes) {
  Cookies.remove(name, options);
}
