import { useEffect, useState } from 'react';

import { RequestProcessingComponent } from 'shared/helpers/request-processing';

export function useLayoutProcessing() {
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    RequestProcessingComponent.register(setProcessing);
    return () => {
      RequestProcessingComponent.unregister(setProcessing);
    };
  }, []);

  return { processing };
}
