import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { UserInfo } from 'entities/session';

import { UserHeaderInfoBody } from '../user-header-info-body';

import { useUserHeaderInfo } from './user-header-info.hook';

interface IUserHeaderInfoProps {
  clickable?: boolean;
}
const UserHeaderInfoComponent: FC<IUserHeaderInfoProps> = ({ clickable = true }) => {
  const { authenticated, visible, onShow, onClose } = useUserHeaderInfo();

  return (
    <>
      <UserInfo
        onClick={clickable ? onShow : undefined}
        className={clickable ? 'cursor-pointer' : undefined}
      />
      {authenticated && <UserHeaderInfoBody visible={visible} onClose={onClose} />}
    </>
  );
};

export const UserHeaderInfo = observer(UserHeaderInfoComponent);
