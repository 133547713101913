import clsx from 'clsx';
import { FC, PropsWithChildren, memo } from 'react';
import { createPortal } from 'react-dom';

import { IUseTransitionElementArgs, useTransitionElement } from './transition-element.hook';

interface ITransitionElementProps extends IUseTransitionElementArgs {
  className: string;
}

const TransitionElementComponent: FC<PropsWithChildren<ITransitionElementProps>> = ({
  className,
  children,
  ...args
}) => {
  const { elementRef, mounted, status } = useTransitionElement(args);
  return mounted && elementRef.current
    ? createPortal(<div className={clsx(className, status)}>{children}</div>, elementRef.current)
    : null;
};

export const TransitionElement = memo(TransitionElementComponent);
