import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { SessionStore } from 'entities/session';

import { Preloader } from 'shared/ui/layout';

export function withSessionCheck(component: () => JSX.Element) {
  const SessionCheckProviderComponent = observer(function SessionCheckProvider() {
    const { initialized, init } = SessionStore;

    useEffect(() => {
      init();
    }, []);
    if (!initialized) {
      return <Preloader />;
    }
    return <>{component()}</>;
  });
  return function SessionCheckProvider() {
    return <SessionCheckProviderComponent />;
  };
}
