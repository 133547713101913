import clsx from 'clsx';
import { FC } from 'react';

import { IUseAvatarArgs, useAvatar } from './avatar.hook';

import styles from './avatar.module.scss';

interface IAvatarProps extends IUseAvatarArgs {
  radius?: 'default' | 'large' | 'full';
  className?: string;
}

export const Avatar: FC<IAvatarProps> = ({ radius = 'full', className, ...args }) => {
  const { style, image } = useAvatar(args);
  return (
    <div className={clsx(styles.container, styles[`radius-${radius}`], className)} style={style}>
      {image && <img src={image} alt='' style={style} />}
    </div>
  );
};
