import clsx from 'clsx';
import { FC } from 'react';

import { ISimpleUserResponse } from 'shared/api/user';

import styles from './coach-text-element.module.scss';

interface ICoachTextElementProps extends Pick<ISimpleUserResponse, 'id' | 'name' | 'color'> {
  onClick?: (id: number) => void;
  firstWord?: boolean;
}
export const CoachTextElement: FC<ICoachTextElementProps> = ({
  id,
  name,
  color,
  firstWord,
  onClick,
}) => {
  return (
    <div
      className={clsx(styles.coach, onClick && 'cursor-pointer')}
      style={{ color }}
      onClick={() => {
        if (onClick) {
          onClick(id);
        }
      }}
    >
      {firstWord ? name.split(' ')[0] : name.replaceAll(' ', '\n')}
    </div>
  );
};
