import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Link } from 'react-router-dom';

import { useSessionMainPageRoute } from 'entities/session';

import { useLayoutPropsSetter } from 'shared/context';
import { Content, Text } from 'shared/ui/layout';

const NotFoundComponent: FC = () => {
  const route = useSessionMainPageRoute();
  useLayoutPropsSetter({});
  return (
    <Content className='w-full pt-[200px] flex items-center justify-center flex-col'>
      <Text type='title' align='center' className='mb-21px'>
        Страница не найдена
      </Text>
      <Link to={route} className='text-primary text-center hover:text-primary-dark'>
        Вернуться на главную
      </Link>
    </Content>
  );
};

export const NotFound = observer(NotFoundComponent);
