import { observer } from 'mobx-react-lite';
import { FunctionComponent, MouseEventHandler, useCallback, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import { DialogComponentUtil, DialogIconType } from 'shared/helpers/dialog';
import { Button } from 'shared/ui/controls';

import './dialog.scss';
import iconNext from './icons/icon-next.svg';
import iconWarning from './icons/icon-warning.svg';

const dialogIconMap: Record<DialogIconType, string> = {
  warning: iconWarning,
  next: iconNext,
};

const DialogComponent: FunctionComponent = () => {
  const { visible, dialog, actions, performAction, decline: close } = DialogComponentUtil;
  const { icon, title, text } = dialog || {};

  const bodyRef = useRef<HTMLDivElement>(null);

  const handleBodyClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      if (bodyRef.current && !bodyRef.current.contains(e.target as Node)) close();
    },
    [bodyRef.current, close],
  );

  return (
    <>
      <CSSTransition in={visible} timeout={300} classNames='dialog' unmountOnExit>
        <div className='dialog__body-wrapper' onClick={handleBodyClick}>
          <div className='dialog__body' ref={bodyRef}>
            {icon && (
              <img
                src={dialogIconMap[icon]}
                alt=''
                width={90}
                height={90}
                className='mb-30px mx-auto'
              />
            )}
            {title && (
              <div className='text-sm leading-1_41 mb-6px font-bold whitespace-pre-line'>
                {title}
              </div>
            )}
            {text && <div className='text-sm leading-1_41 whitespace-pre-line'>{text}</div>}
            {actions.length > 0 && (
              <div>
                {actions.map(({ name, label }) => (
                  <Button
                    key={name}
                    onClick={() => performAction(name)}
                    buttonType='gray'
                    font='default'
                    className='mb-3px first:mt-18px last:mb-0'
                  >
                    {label}
                  </Button>
                ))}
              </div>
            )}
            <button type='button' className='mt-18px' onClick={close}>
              Назад
            </button>
          </div>
        </div>
      </CSSTransition>
      {visible && <div className='dialog__hover' />}
    </>
  );
};

export const Dialog = observer(DialogComponent);
