import { forwardRef, memo } from 'react';

import { IFileDto } from 'entities/file';

import { Button, IButtonProps } from '../../../shared/ui/controls/button';

import { useFileUploader } from './file-uploader.hook';

interface IFileUploaderProps extends Omit<IButtonProps, 'onClick'> {
  onAccept: (file: IFileDto) => void;
}
export const FileUploader = memo(
  forwardRef<HTMLButtonElement, IFileUploaderProps>(function FileUploader(
    { onAccept, ...rest },
    ref,
  ) {
    const { onChange, onClick, uploading, inputRef } = useFileUploader(onAccept);
    return (
      <>
        <Button {...rest} ref={ref} onClick={onClick} />
        <input
          ref={inputRef}
          style={{ display: 'none' }}
          type='file'
          onChange={uploading ? undefined : onChange}
        />
      </>
    );
  }),
);
