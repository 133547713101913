import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { AbonementIcon } from 'entities/abonement';
import { useStat } from 'entities/stat';

import styles from './user-abonement-info-stat.module.scss';

interface IUserAbonementInfoStatProps {
  onClick: () => void;
}
const UserAbonementInfoStatComponent: FC<IUserAbonementInfoStatProps> = ({ onClick }) => {
  const { stat } = useStat().clientStat;
  if (!stat?.currentAbonement) {
    return <></>;
  }
  return (
    <div onClick={onClick} className={styles.container}>
      <AbonementIcon type={stat.currentAbonement.type} width={50} />
      <div className={styles.count}>{stat.currentAbonement.lessonCount}</div>
    </div>
  );
};

export const UserAbonementInfoStat = observer(UserAbonementInfoStatComponent);
