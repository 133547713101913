import clsx from 'clsx';
import { PropsWithChildren, forwardRef } from 'react';

import styles from './content.module.scss';

type ContentPaddingType = 'default' | 'medium' | 'small' | 'none';
type ContentMobilePaddingType = 'default' | 'small';
type IContentProps = {
  className?: string;
} & (
  | {
      type?: 'default' | undefined;
      padding?: ContentPaddingType;
      mobilePadding?: ContentMobilePaddingType;
    }
  | {
      type: 'tiny' | 'modal-form' | 'modal-form-wide' | 'modal' | 'modal-tiny';
      padding?: never;
      mobilePadding?: never;
    }
);
export const Content = forwardRef<HTMLDivElement, PropsWithChildren<IContentProps>>(
  function Content(
    { className, type = 'default', padding = 'default', mobilePadding = 'default', children },
    ref,
  ) {
    return (
      <div
        className={clsx(
          styles.content,
          styles[`content-${type}`],
          type === 'default' && padding !== 'none' && styles[`padding-${padding}`],
          type === 'default' &&
            mobilePadding !== 'default' &&
            styles[`padding-mobile-${mobilePadding}`],
          className,
        )}
        ref={ref}
      >
        {children}
      </div>
    );
  },
);
