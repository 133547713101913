import clsx from 'clsx';
import { CSSProperties, PropsWithChildren, forwardRef } from 'react';

import styles from './card.module.scss';

type CardColorType = 'default' | 'gray' | 'light-gray';
type CardRadiusType = 'default' | 'unset' | 'thin' | 'medium' | 'small';
type CardPaddingType = 'default' | 'medium' | 'unset';
type ICardProps = {
  className?: string;
  style?: CSSProperties;
  color?: CardColorType;
  padding?: CardPaddingType;
  onClick?: () => void;
} & (
  | {
      radius?: CardRadiusType;
      topRadius?: never;
      bottomRadius?: never;
    }
  | {
      radius: 'separate';
      topRadius?: CardRadiusType;
      bottomRadius?: CardRadiusType;
    }
);
export const Card = forwardRef<HTMLDivElement, PropsWithChildren<ICardProps>>(function Card(
  {
    onClick,
    className,
    style,
    color = 'default',
    padding = 'default',
    radius = 'default',
    topRadius = 'default',
    bottomRadius = 'default',
    children,
  },
  ref,
) {
  let resultClassName = clsx(
    styles[`color-${color}`],
    padding !== 'unset' && styles[`padding-${padding}`],
  );
  if (radius === 'separate') {
    resultClassName = clsx(
      resultClassName,
      topRadius !== 'unset' && styles[`top-radius-${topRadius}`],
      bottomRadius !== 'unset' && styles[`bottom-radius-${bottomRadius}`],
    );
  } else {
    resultClassName = clsx(resultClassName, radius !== 'unset' && styles[`radius-${radius}`]);
  }

  return (
    <div onClick={onClick} className={clsx(resultClassName, className)} style={style} ref={ref}>
      {children}
    </div>
  );
});
