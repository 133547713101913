import { IStatClientRes } from 'entities/stat/api/payload/stat-client-res';
import { getClientStat } from 'entities/stat/api/service/get-client-stat';

import { StatInfoVM } from './stat-info.vm';

export class StatClientInfoVM extends StatInfoVM<IStatClientRes> {
  protected getLoader(): Promise<IStatClientRes> {
    return getClientStat();
  }
}
