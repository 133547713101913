import clsx from 'clsx';
import { FC } from 'react';

import icon from './preloader.svg';

import styles from './preloader.module.scss';

export const Preloader: FC<{
  type?: 'fixed' | 'absolute' | 'block';
  size?: number | `${number}`;
  className?: string;
}> = ({ type = 'fixed', size = 60, className }) => (
  <div className={clsx(styles[`${type}-element`], className)}>
    <div className={styles['lds-spinner']}>
      <img className={styles['lds-inner-spinner']} src={icon} alt='' width={size} height={size} />
    </div>
  </div>
);
