import { clsx } from 'clsx';
import { ChangeEventHandler, FC, InputHTMLAttributes, memo, useId, useRef } from 'react';

import { IInputDefaultProps } from '../input';

import styles from './checkbox.module.scss';

interface ICheckboxProps
  extends IInputDefaultProps,
    Omit<InputHTMLAttributes<HTMLInputElement>, 'type' | 'value'> {
  containerWidth?: 'full' | 'content' | 'unset';
}

const CheckboxComponent: FC<ICheckboxProps> = ({
  wrapperClassName,
  className,
  label,
  onChange,
  containerWidth = 'content',
  id,
  checked,
  ...rest
}) => {
  const inputId = useId();
  const idRef = useRef(id || inputId);
  const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    e.stopPropagation();
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <label
      className={clsx(
        styles.container,
        containerWidth !== 'unset' && styles[`container_width-${containerWidth}`],
        wrapperClassName,
      )}
      htmlFor={idRef.current}
    >
      {label && <span className={styles.label}>{label}</span>}
      <input
        id={idRef.current}
        type='checkbox'
        checked={checked}
        {...rest}
        onChange={handleChange}
      />
      <span
        className={clsx(styles.checkmark, checked && styles.checkmark_checked, className)}
      ></span>
    </label>
  );
};

export const Checkbox = memo(CheckboxComponent);
