import { Key } from 'react';

import {
  getSelectableOptionLabelProperty,
  getSelectableOptionValueProperty,
} from 'shared/helpers/component-helpers';
import iconChecked from 'shared/icons/icon-check.svg';

import { Button } from '../button';
import { ControlLabel, IControlLabelProps } from '../control-label';

interface ISelectorProps<T extends SelectableOptionType> extends IControlLabelProps {
  wrapperClassName?: string;
  className?: string;
  options: T[];
  selectedValue?: SelectableValueType<T>;
  onSelect: (value: T) => void;
  valueProperty: AffordableSelectorKey<T>;
  labelProperty: AffordableSelectorKey<T>;
}

export function Selector<T extends SelectableOptionType>({
  options,
  selectedValue,
  onSelect,
  valueProperty,
  labelProperty,
  className,
  wrapperClassName,
  ...labelProps
}: ISelectorProps<T>) {
  const selectedKey = getSelectableOptionValueProperty(valueProperty, selectedValue);
  return (
    <div className={wrapperClassName}>
      <ControlLabel {...labelProps} />
      <div className='flex gap-9px'>
        {options.map((option) => {
          const isSelected = selectedKey === option[valueProperty];
          return (
            <Button
              type='button'
              key={option[valueProperty] as Key}
              buttonType={isSelected ? 'dark' : 'gray'}
              height='medium'
              font='default'
              radius='small'
              contentType={isSelected ? 'center' : 'default'}
              className={className}
              onClick={() => onSelect(option)}
            >
              {isSelected && <img src={iconChecked} alt='' width={16} height={11} />}
              {getSelectableOptionLabelProperty(labelProperty, option)}
            </Button>
          );
        })}
      </div>
    </div>
  );
}
