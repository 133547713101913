import clsx from 'clsx';
import { ReactNode } from 'react';

import { TransitionElement } from 'shared/ui/layout';

import { ISelectPopupBodyDefaultProps, SelectPopupBody } from './select-popup-body';
import {
  ISelectPopupDefaultControlProps,
  SelectPopupDefaultControl,
} from './select-popup-default-control';
import { IUseSelectPopupArgs, useSelectPopup } from './select-popup.hook';

import styles from './select-popup.module.scss';

type ISelectPopupProps<T extends SelectableOptionType> = IUseSelectPopupArgs<T> &
  ISelectPopupBodyDefaultProps<T> & {
    className?: string;
    disabled?: boolean;
  } & (
    | { children: ReactNode; controlProps?: never }
    | {
        children?: never;
        controlProps?: Omit<
          ISelectPopupDefaultControlProps<T>,
          'value' | 'options' | 'valueProperty' | 'labelProperty'
        >;
      }
  );

export function SelectPopup<T extends SelectableOptionType>({
  className,
  options,
  onSelect,
  children,
  disabled,
  controlProps = {},

  ...args
}: ISelectPopupProps<T>) {
  const { visible, onOpen, onClose, onSelect: onOptionSelect } = useSelectPopup<T>({ onSelect });

  let control: ReactNode;
  if (children) {
    control = children;
  } else {
    const { value, labelProperty, valueProperty } = args;
    control = (
      <SelectPopupDefaultControl
        {...controlProps}
        value={value}
        options={options}
        valueProperty={valueProperty}
        labelProperty={labelProperty}
      />
    );
  }
  return (
    <>
      <div
        className={clsx(
          styles['control-wrapper'],
          disabled && styles['control-wrapper_disabled'],
          className,
        )}
        onClick={onOpen}
      >
        {control}
      </div>
      <TransitionElement visible={visible} className={styles.modal}>
        <SelectPopupBody onClose={onClose} options={options} onSelect={onOptionSelect} {...args} />
      </TransitionElement>
    </>
  );
}
