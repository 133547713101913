import { SessionStore } from 'entities/session';

export function useSessionMainPageRoutePure({
  authenticated,
  isAdmin,
  isCoach,
}: {
  authenticated: boolean;
  isAdmin: boolean;
  isCoach: boolean;
}) {
  if (!authenticated) {
    return '/';
  }
  if (isAdmin) {
    return `/admin/profile`;
  }
  if (isCoach) {
    return `/coach/profile`;
  }
  return `/profile`;
}

export function useSessionMainPageRoute() {
  return useSessionMainPageRoutePure(SessionStore);
}
