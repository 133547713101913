import { FC } from 'react';

import { IInputDefaultProps, Input } from '../input';

import { useSearchInput } from './search-input.hook';

interface ISearchInputProps extends IInputDefaultProps {
  onSearch: (text: string, isClear?: boolean) => void;
  placeholder?: string;
  className?: string;
}
export const SearchInput: FC<ISearchInputProps> = ({ onSearch, ...inputProps }) => {
  const { searchText, onSearchTextChange } = useSearchInput(onSearch);
  return <Input {...inputProps} value={searchText} onChange={onSearchTextChange} />;
};
