import { FC, PropsWithChildren } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { SessionStore } from 'entities/session';

export const ProtectedCoachRoute: FC<Partial<PropsWithChildren>> = ({ children }) => {
  const { isCoach } = SessionStore;
  if (!isCoach) {
    return <Navigate to='/' />;
  }
  return <>{children || <Outlet />}</>;
};
