import clsx from 'clsx';
import { FC } from 'react';

import { IAbonementTypeCollectionItemRes } from 'entities/abonement/api/payload';
import { LESSON_PLURAL_VALUES } from 'entities/training';

import {
  countToStringWithCounter,
  getNormalizedPriceValue,
} from 'shared/helpers/component-helpers';
import { Button } from 'shared/ui/controls';

import styles from './price-info.module.scss';

interface IPriceInfoProps {
  trial?: boolean;
  name?: string;
  item: IAbonementTypeCollectionItemRes;
  direction?: 'column' | 'row';
  priceMarked?: boolean;
  onAction?: (id: number) => void;
  actionText?: string;
  className?: string;
}
export const PriceInfo: FC<IPriceInfoProps> = ({
  item,
  onAction,
  trial,
  name,
  priceMarked,
  actionText = 'Купить',
  direction = 'column',
  className,
}) => {
  const { id, price, lessonCount, fullPrice, duration } = item;
  return (
    <div className={clsx(styles.container, styles[`container-${direction}`], className)}>
      <div className={styles.title}>
        {trial
          ? name === 'trial'
            ? 'Пробное занятие'
            : 'Пробное индивидуальное'
          : countToStringWithCounter(lessonCount, ...LESSON_PLURAL_VALUES)}
      </div>
      <div className={styles.content}>
        {!trial && <div className={styles.subtitle}>на {duration} дней</div>}
        <div className={clsx(styles.price, priceMarked && styles.price_emphasised)}>
          <span>{getNormalizedPriceValue(price)}</span>
          <span className={styles.price_currency}>₽</span>
        </div>
        {fullPrice && (
          <div className={clsx(styles.price, styles.price_crossed)}>
            <span>{getNormalizedPriceValue(fullPrice)}</span>
            <span className={styles.price_currency}>₽</span>
          </div>
        )}
        {!trial && (
          <div className={styles['price-subtitle']}>
            {getNormalizedPriceValue(price / lessonCount)} за час
          </div>
        )}
        {onAction && (
          <Button
            buttonType='main-light'
            width='content'
            height='unset'
            className={styles.action}
            onClick={() => onAction(id)}
          >
            {actionText}
          </Button>
        )}
      </div>
    </div>
  );
};
