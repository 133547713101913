import { FC } from 'react';
import { FieldError } from 'react-hook-form';

import styles from './control-label.module.scss';

export interface IControlLabelProps {
  id?: string;
  label?: string;
  error?: FieldError | string;
}
export const ControlLabel: FC<IControlLabelProps> = ({ id, label, error }) => {
  if (!label && !error) {
    return null;
  }
  return (
    <label htmlFor={id} className={styles.container}>
      {label}
      {error && (
        <span className={styles.error}>{typeof error === 'string' ? error : error.message}</span>
      )}
    </label>
  );
};
