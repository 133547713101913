import clsx from 'clsx';

import { ControlLabel, IControlLabelProps } from '../../control-label';

import {
  IUseSelectPopupDefaultControlArgs,
  useSelectPopupDefaultControl,
} from './select-popup-default-control.hook';

import styles from '../select-popup.module.scss';

export interface ISelectPopupDefaultControlProps<T extends SelectableOptionType>
  extends IControlLabelProps,
    IUseSelectPopupDefaultControlArgs<T> {}
export function SelectPopupDefaultControl<T extends SelectableOptionType>({
  label,
  error,
  id,
  ...args
}: ISelectPopupDefaultControlProps<T>) {
  const { controlValue, isPlaceholderViewed } = useSelectPopupDefaultControl(args);
  return (
    <>
      <ControlLabel id={id} label={label} error={error} />
      <div
        className={clsx(
          styles.control,
          isPlaceholderViewed && styles.control_placeholder,
          error && styles.control_error,
        )}
      >
        {controlValue}
      </div>
    </>
  );
}
