import { clsx } from 'clsx';
import { CSSProperties, InputHTMLAttributes, forwardRef, memo, useId, useRef } from 'react';

import { ControlLabel, IControlLabelProps } from '../control-label';

import styles from './input.module.scss';

export interface IInputDefaultProps extends IControlLabelProps {
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
}

interface IInputProps extends InputHTMLAttributes<HTMLInputElement>, IInputDefaultProps {}

export const Input = memo(
  forwardRef<HTMLInputElement, IInputProps>(function Input(
    { id, error, type = 'text', className, wrapperClassName, wrapperStyle, label, ...rest },
    ref,
  ) {
    const inputId = useId();
    const idRef = useRef(id || inputId);
    return (
      <div className={wrapperClassName} style={wrapperStyle}>
        <ControlLabel id={idRef.current} label={label} error={error} />
        <input
          id={idRef.current}
          ref={ref}
          type={type}
          className={clsx(styles.control, error && styles.control_error, className)}
          {...rest}
        />
      </div>
    );
  }),
);
