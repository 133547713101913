import { ChangeEventHandler, useCallback, useEffect, useState } from 'react';

export function useSearchInput(onSearch: (searchText: string) => void) {
  const [searchText, setSearchText] = useState('');

  const handleTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>((e) => {
    setSearchText(e.target.value);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      onSearch(searchText.trim());
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [searchText, onSearch]);

  return {
    searchText,
    onSearchTextChange: handleTextChange,
  };
}
