import { createContext, useContext, useEffect } from 'react';

import { ILayoutState } from 'shared/ui/layout/layout';

type LayoutContextType = (props: ILayoutState) => void;
const LayoutContext = createContext<Unset<LayoutContextType>>(undefined);
const { Provider } = LayoutContext;

export { Provider as LayoutContextProvider };
export function useLayoutPropsSetter(props: ILayoutState) {
  const onChange = useContext(LayoutContext);
  useEffect(() => {
    if (onChange) {
      onChange(props);
    }
  }, []);
}
