import { useCallback } from 'react';

export interface IUseSelectPopupOptionArgs<T extends SelectableOptionType> {
  option: T;
  onSelect: (option: T) => void;
}

export function useSelectPopupOption<T extends SelectableOptionType>({
  option,
  onSelect,
}: IUseSelectPopupOptionArgs<T>) {
  const handleClick = useCallback(() => {
    onSelect(option);
  }, [option, onSelect]);

  return {
    onClick: handleClick,
  };
}
