import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { IUseAbonementIconArgs, useAbonementIcon } from './abonement-icon.hook';

import styles from './abonement-icon.module.scss';

interface IAbonementIconProps extends IUseAbonementIconArgs {
  className?: string;
  width?: number;
}
const AbonementIconComponent: FC<IAbonementIconProps> = ({ className, width, ...args }) => {
  const { src } = useAbonementIcon(args);
  if (!src) {
    return <div className={clsx(styles.blank, className)} style={{ width }} />;
  }
  return <img src={src} width={width} className={className} alt='' />;
};

export const AbonementIcon = observer(AbonementIconComponent);
