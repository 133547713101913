import { useMemo } from 'react';

import { useSession } from 'entities/session';
import { useStat } from 'entities/stat';

import { countToStringWithCounter } from 'shared/helpers/component-helpers';
import { DateUtil } from 'shared/helpers/date';

export function useUserHeaderClientInfo() {
  const { registeredAt, birthday } = useSession().sessionInfo;
  const { finishedLessonsCount, currentDate } = useStat().clientStat.stat || {};

  const duration = useMemo(() => {
    if (!currentDate || !registeredAt) {
      return '';
    }
    const now = DateUtil.getDateObj(currentDate)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    const registered = DateUtil.getDateObj(registeredAt)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    const expiredYears = now.diff(registered, 'year');
    if (expiredYears > 0) {
      return countToStringWithCounter(expiredYears, 'год', 'года', 'лет');
    }
    const expiredMonth = now.diff(registered, 'month');
    if (expiredMonth > 0) {
      return countToStringWithCounter(expiredYears, 'месяц', 'месяца', 'месяцев');
    }
    const expiredDay = now.diff(registered, 'day');
    return countToStringWithCounter(expiredDay > 0 ? expiredDay : 0, 'день', 'дня', 'дней');
  }, [registeredAt, currentDate]);


  return {
    duration,
    birthday,
    finishedLessonsCount,
  };
}
