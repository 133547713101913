import { FC, PropsWithChildren, memo } from 'react';

import { TransitionElement } from '../transition-element';

import { IModalBodyProps, ModalBody } from './modal-body';

import styles from './modal.module.scss';

type ModalPropsType = IModalBodyProps & {
  visible: boolean;
};

const ModalComponent: FC<PropsWithChildren<ModalPropsType>> = ({ visible, ...bodyProps }) => {
  return (
    <TransitionElement visible={visible} className={styles.modal}>
      <ModalBody {...bodyProps} />
    </TransitionElement>
  );
};

export const Modal = memo(ModalComponent);
