import { FC, memo } from 'react';

import { Preloader } from '../preloader';

import { useLayoutProcessing } from './layout-processing.hook';

const LsyoutProcessingComponent: FC = () => {
  const { processing } = useLayoutProcessing();
  if (processing) {
    return <Preloader className='z-preloader' />;
  }
  return <></>;
};

export const LayoutProcessing = memo(LsyoutProcessingComponent);
