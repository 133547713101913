import clsx from 'clsx';
import { CSSProperties, FC, PropsWithChildren, memo } from 'react';

import { Header } from '../../header';
import { IUseTransitionElementBodyArgs } from '../../transition-element';

import { ModalBodyContextProvider } from './modal-body.context';
import { useModalBody } from './modal-body.hook';

import styles from '../modal.module.scss';

export interface IModalBodyProps extends IUseTransitionElementBodyArgs {
  className?: string;
  style?: CSSProperties;
  contentColor?: 'default' | 'white' | 'unset';
  top?: 'default' | 'unset';
  bottom?: 'default' | 'unset';
}

const ModalBodyComponent: FC<PropsWithChildren<IModalBodyProps>> = ({
  className,
  style,
  contentColor = 'default',
  top = 'default',
  bottom = 'default',
  children,
  onClose,
  noExternal,
}) => {
  const { wrapperRef, contentRef, value } = useModalBody({ onClose, noExternal });
  return (
    <ModalBodyContextProvider value={value}>
      <div className={styles.wrapper}>
        <div
          ref={wrapperRef}
          className={clsx('modal-container-wrapper', styles['container-wrapper'])}
        >
          <div className={styles.container}>
            <div
              className={clsx(
                styles.content,
                contentColor !== 'unset' && styles[`content-color-${contentColor}`],
                className,
              )}
              style={style}
              ref={contentRef}
            >
              <Header onBack={onClose} wrapperClassName={styles.header} profileClickable={false} />
              <div
                className={clsx(
                  top !== 'unset' && styles[`offset-top-${top}`],
                  bottom !== 'unset' && styles[`offset-bottom-${bottom}`],
                )}
              >
                {children}
              </div>
            </div>
          </div>
        </div>
        <div className={styles.overlay} />
      </div>
    </ModalBodyContextProvider>
  );
};

export const ModalBody = memo(ModalBodyComponent);
