import { useCallback } from 'react';

import { ICalendarDayCoachRes } from 'entities/training/api';

import { DateType, DateUtil } from 'shared/helpers/date';

export interface IUseCoachDaySlotsArgs {
  data: ICalendarDayCoachRes;
  onClick: (date: DateType, time: number) => void;
}

export function useCoachDaySlots({ data: { date, slots }, onClick }: IUseCoachDaySlotsArgs) {
  const handleClick = useCallback(
    (time: number) => {
      onClick(DateUtil.getDateObj(date), time);
    },
    [onClick, date],
  );

  return {
    slots: slots,
    onClick: handleClick,
  };
}
