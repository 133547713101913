import clsx from 'clsx';
import { FC } from 'react';

import { Avatar } from 'entities/file';

import { ISimpleUserResponse } from 'shared/api/user';
import iconIndividual from 'shared/icons/icon-individual.svg';
import { Text } from 'shared/ui/layout';

import styles from './simple-user-element.module.scss';

export const SimpleUserElement: FC<{
  data: ISimpleUserResponse;
  onClick?: (entity: ISimpleUserResponse) => void;
  className?: string;
  dotOverflow?: boolean;
}> = ({ onClick, className, data, dotOverflow = true }) => {
  const handleItemClick = () => {
    if (onClick) {
      onClick(data);
    }
  };
  const { name, color, avatar, individual } = data;
  return (
    <div
      onClick={onClick ? handleItemClick : undefined}
      className={clsx(styles.wrapper, className, onClick && 'cursor-pointer')}
    >
      <Avatar size={24} file={avatar} />
      <Text type='small-mobile-text' element='div' space='nowrap' dotOverflow={dotOverflow}>
        {name}
      </Text>
      {color && <div className={styles.color} style={{ backgroundColor: color }} />}
      {individual && <img src={iconIndividual} alt='' width={13} height={13} />}
    </div>
  );
};
