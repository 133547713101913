import { action, makeObservable, observable } from 'mobx';

import { getUuid } from '../component-helpers';

import {
  DialogActionType,
  DialogInfoType,
  DialogShowDiwDialogParams,
  IDialogUtil,
} from './dialog.type';

class DialogUtilClass implements IDialogUtil {
  visible = false;

  dialog: Nullable<DialogInfoType> = null;

  private _actionsMap: Map<string, DialogActionType> = new Map();
  private _onDecline?: () => void;

  get actions(): Array<{ name: string; label: string }> {
    return Array.from(this._actionsMap.entries()).map(([actionName, actionValue]) => ({
      name: actionName,
      label: actionValue.label,
    }));
  }

  constructor() {
    makeObservable<DialogUtilClass, '_actionsMap'>(this, {
      visible: observable,
      dialog: observable,
      _actionsMap: observable,
      showDialog: action.bound,
      performAction: action.bound,
      decline: action.bound,
    });
  }

  showDialog({
    icon = 'warning',
    title,
    text,
    onAction,
    onDecline,
    actionText = 'Продолжить',
    actions,
  }: DialogShowDiwDialogParams) {
    this._onDecline = onDecline;
    this.dialog = { icon, title, text };
    if (onAction) {
      this._actionsMap = new Map([['main', { label: actionText, action: onAction }]]);
    } else if (actions) {
      this._actionsMap = new Map(actions.map((action) => [getUuid(), action]));
    } else {
      this._actionsMap = new Map();
    }
    this.visible = true;
  }

  performAction(name: string) {
    this.visible = false;
    const action = this._actionsMap.get(name);
    if (!action) {
      return;
    }
    action.action();
  }

  decline() {
    this.visible = false;
    if (this._onDecline) {
      this._onDecline();
    }
  }
}

const util = new DialogUtilClass();
const DialogUtil = util as IDialogUtil;
export { DialogUtil, util as DialogComponentUtil };
