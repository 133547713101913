import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useSessionMainPageRoute } from 'entities/session';

interface IProtectedUserRouteProps {
  authenticated: boolean;
  isAdmin: boolean;
  isCoach: boolean;
  children?: ReactNode;
}
export const ProtectedUserRoute: FC<IProtectedUserRouteProps> = ({
  authenticated,
  isAdmin,
  isCoach,
  children,
}) => {
  const mainRoute = useSessionMainPageRoute();
  if (authenticated && !isCoach && !isAdmin) {
    return <>{children || <Outlet />}</>;
  }
  return <Navigate to={mainRoute} />;
};
