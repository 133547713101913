import { useCallback, useState } from 'react';

import { useSession } from 'entities/session';
import { useStat } from 'entities/stat';

export interface IUseUserHeaderInfoBodyArgs {
  onClose: () => void;
}

export function useUserHeaderInfoBody({ onClose }: IUseUserHeaderInfoBodyArgs) {
  const [mode, setMode] = useState<'view' | 'edit' | 'edit-schedule'>('view');

  const { reloadSession, clearSession } = useSession();
  const { refetch } = useStat().currentStat;

  const handleSaveProfile = useCallback(() => {
    reloadSession();
    setMode('view');
  }, [reloadSession]);
  const handleLogout = useCallback(() => {
    onClose();
    clearSession();
  }, [onClose, clearSession]);
  const handleShowProfileEditor = useCallback(() => {
    setMode('edit');
  }, []);
  const handleShowScheduleEditor = useCallback(() => {
    setMode('edit-schedule');
  }, []);
  const handleSaveSchedule = useCallback(() => {
    refetch();
    setMode('view');
  }, [refetch]);

  const handleBack = useCallback(() => {
    if (mode === 'edit' || mode === 'edit-schedule') {
      setMode('view');
    } else {
      onClose();
    }
  }, [mode, onClose]);

  return {
    mode,
    onShowScheduleEditor: handleShowScheduleEditor,
    onShowProfileEditor: handleShowProfileEditor,
    onSaveProfile: handleSaveProfile,
    onSaveSchedule: handleSaveSchedule,
    onLogout: handleLogout,
    onBack: handleBack,
  };
}
