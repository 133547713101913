import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';

type MaxWidthType = 'desktop' | 'default';

const minWidthTypeMap: Record<MaxWidthType, number> = {
  default: 600,
  desktop: 1200,
};

export const DesktopView: FC<PropsWithChildren<{ from?: MaxWidthType }>> = ({
  children,
  from = 'default',
}) => {
  return <MediaQuery minWidth={minWidthTypeMap[from]}>{children}</MediaQuery>;
};
