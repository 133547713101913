import { IFileDto } from 'entities/file';

import { baseApi } from 'shared/api/axios';

export function uploadFile(file: File) {
  const fd = new FormData();
  fd.append('file', file);
  return baseApi.post<IFileDto>('/upload', fd, {
    headers: { 'Content-Type': 'multipart/form-data' },
    service: 'file',
  });
}
