import { clsx } from 'clsx';
import { CSSProperties, TextareaHTMLAttributes, forwardRef, memo, useId, useRef } from 'react';

import { ControlLabel, IControlLabelProps } from '../control-label';

import styles from './textarea.module.scss';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement>, IControlLabelProps {
  wrapperClassName?: string;
  wrapperStyle?: CSSProperties;
  fixed?: boolean;
}

export const TextArea = memo(
  forwardRef<HTMLTextAreaElement, ITextAreaProps>(function TextArea(
    { id, error, className, wrapperClassName, wrapperStyle, label, fixed, ...rest },
    ref,
  ) {
    const inputId = useId();
    const idRef = useRef(id || inputId);
    return (
      <div className={wrapperClassName} style={wrapperStyle}>
        <ControlLabel id={idRef.current} label={label} error={error} />
        <textarea
          id={idRef.current}
          ref={ref}
          className={clsx(
            styles.control,
            error && styles.control_error,
            fixed && styles.control_fixed,
            className,
          )}
          {...rest}
        />
      </div>
    );
  }),
);
