import { FC, PropsWithChildren } from 'react';
import MediaQuery from 'react-responsive';

type MaxWidthType = 'desktop' | 'default';

const maxWidthTypeMap: Record<MaxWidthType, number> = {
  default: 599,
  desktop: 1199,
};

export const MobileView: FC<PropsWithChildren<{ to?: MaxWidthType }>> = ({
  children,
  to = 'default',
}) => {
  return <MediaQuery maxWidth={maxWidthTypeMap[to]}>{children}</MediaQuery>;
};
