import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Controller } from 'react-hook-form';

import { FileUploader } from 'features/file-uploader';
import { UserSexEditor } from 'features/user/user-sex-editor';

import { Avatar } from 'entities/file';

import { Button, DateInput, Input } from 'shared/ui/controls';
import { PhoneInput } from 'shared/ui/controls/phone-input';
import { Text } from 'shared/ui/layout';

import { IUseUserProfileEditorArgs, useUserProfileEditor } from './user-profile-editor.hook';

const UserProfileEditorComponent: FC<IUseUserProfileEditorArgs> = (args) => {
  const { register, initialSex, control, errors, avatar, onAvatarChange, onSubmit, onSexChange } =
    useUserProfileEditor(args);

  return (
    <form id='edit-profile-form' onSubmit={onSubmit}>
      <Text type='block-title' className='mb-3px'>
        Мои данные
      </Text>
      <Text type='main' className='mb-21px'>
        Заполните полностью, чтобы получать больше преимуществ!
      </Text>
      <div className='flex mb-3 gap-3'>
        <Avatar size={106} radius='default' file={avatar} />
        <FileUploader
          onAccept={onAvatarChange}
          buttonType='gray'
          font='default'
          height='medium'
          width='content'
        >
          Изменить фото
        </FileUploader>
      </div>
      <Input
        {...register('name', { required: 'Укажите имя' })}
        error={errors.name}
        label='Имя и фамилия'
        wrapperClassName='mb-3'
      />
      <UserSexEditor initial={initialSex} onChange={onSexChange} className='mb-3' />
      <Controller
        render={(ref) => (
          <DateInput
            selected={ref.field.value ? new Date(Number(ref.field.value)) : undefined}
            onChange={(date: Date) => ref.field.onChange(date?.getTime())}
            label='Дата рождения'
            error={errors.birthday}
            dateInputClassName='mb-3'
          />
        )}
        name='birthday'
        control={control}
      />
      <Controller
        render={(ref) => (
          <PhoneInput
            value={ref.field.value ? ref.field.value : ''}
            onChange={(e) => ref.field.onChange(e.target.value)}
            label='Телефон'
            error={errors.phone}
            wrapperClassName='mb-21px'
          />
        )}
        name='phone'
        control={control}
        rules={{ required: 'Заполните поле' }}
      />
      <Button type='submit' buttonType='dark'>
        Сохранить
      </Button>
    </form>
  );
};

export const UserProfileEditor = observer(UserProfileEditorComponent);
