import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

import { BASE_URL } from 'shared/config/env';
import { SS_COOKIE_ACCESS_TOKEN, getCookie, removeCookie, setCookie } from 'shared/helpers/cookie';
import { showErrorToaster } from 'shared/helpers/toaster';

import { SsError } from './axios.error';
import { IAxiosInstanceRequestConfig } from './axios.type';

interface IAxiosInterceptorRequestConfig extends IAxiosInstanceRequestConfig {
  _retry?: boolean;
  _newToken?: string;
}

async function refreshToken() {
  try {
    const resp = await axios.post(`${BASE_URL}/auth/refreshAuthorizeTokens`);

    if (resp.data.accessToken) {
      setCookie(SS_COOKIE_ACCESS_TOKEN, resp.data.accessToken, {
        secure: true,
        sameSite: 'Lax',
        expires: 30,
      });
    }
    return { success: true };
  } catch {
    return { success: false };
  }
}

export const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const originalConfig = config as IAxiosInterceptorRequestConfig;
    const { withoutToken } = originalConfig;
    if (!withoutToken) {
      const token = getCookie(SS_COOKIE_ACCESS_TOKEN);
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    Promise.reject(new SsError(error));
  },
);
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error: AxiosError) {
    const originalRequest: IAxiosInterceptorRequestConfig = error.config || {};
    if (
      error.response?.status === 500 &&
      (error.response.data as { trace: string })?.trace &&
      (error.response.data as { trace: string }).trace.startsWith(
        'io.jsonwebtoken.ExpiredJwtException',
      )
    ) {
      if (!originalRequest._retry) {
        const token = getCookie(SS_COOKIE_ACCESS_TOKEN);
        if (!token) {
          // window.location.href = '/';
          return Promise.reject(error);
        }
        originalRequest._retry = true;
        const res = await refreshToken();
        if (res.success) {
          return axiosInstance(originalRequest);
        } else {
          removeCookie(SS_COOKIE_ACCESS_TOKEN);
          // window.location.href = '/';
          return Promise.reject(new SsError(error));
        }
      }
    }
    if (error.response?.status === 401) {
      window.location.href = '/';
      return Promise.reject(new SsError(error));
    } else if (originalRequest.enableErrorToast) {
      showErrorToaster(new SsError(error, originalRequest.defaultToastMessage));
    }
    return Promise.reject(new SsError(error));
  },
);
