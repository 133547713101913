import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SessionStore } from 'entities/session';

export function useUserHeaderInfo() {
  const { pathname } = useLocation();

  const [visible, setVisible] = useState(false);
  const { authenticated } = SessionStore;

  const handleShow = useCallback(() => {
    setVisible(true);
  }, []);
  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  useEffect(() => {
    setVisible(false);
  }, [pathname]);

  return {
    visible,
    authenticated,
    onShow: handleShow,
    onClose: handleClose,
  };
}
