import { useCallback } from 'react';

import { getSelectableOptionValueProperty } from 'shared/helpers/component-helpers';

export interface IUseSelectPopupBodyArgs<T extends SelectableOptionType> {
  value?: SelectableValueType<T>;
  valueProperty: AffordableSelectorKey<T>;
  onSelect: (value: T) => void;
}

export function useSelectPopupBody<T extends SelectableOptionType>({
  value,
  valueProperty,
  onSelect,
}: IUseSelectPopupBodyArgs<T>) {
  const selectedValue = getSelectableOptionValueProperty(valueProperty, value);
  const handleSelect = useCallback(
    (option: T) => {
      if (selectedValue !== option[valueProperty]) {
        onSelect(option);
      }
    },
    [onSelect, selectedValue],
  );

  return {
    selectedValue,
    onSelect: handleSelect,
  };
}
