import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useScreenLayoutDetect } from 'shared/hooks';

import { ILayoutState } from './layout.props';

export function useLayout() {
  const [scrolled, setScrolled] = useState(false);
  const [{ background = 'unset', backUrl, className }, setProps] = useState<ILayoutState>({});
  const { isMobileLayout } = useScreenLayoutDetect();
  const navigate = useNavigate();
  const { key, pathname } = useLocation();
  useEffect(() => {
    if (!isMobileLayout) {
      return;
    }
    setScrolled(window.scrollY > 0);
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    document.addEventListener('scroll', handleScroll, false);
    return () => {
      document.removeEventListener('scroll', handleScroll, false);
    };
  }, [isMobileLayout]);

  const onBack = () => {
    if (backUrl) {
      if (key === 'default') {
        navigate(backUrl, { replace: true });
      } else {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    document.documentElement.style.setProperty('--scroll-padding', `${scrollbarWidth}px`);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return {
    background,
    allowBack: !!backUrl,
    className,
    mobileScrolled: isMobileLayout && scrolled,
    setProps,
    onBack,
  };
}
