import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { IResponseError } from 'shared/api/axios';
import { DateUtil } from 'shared/helpers/date';

import { IStatBaseRes } from '../../api/payload/stat-base-res';

export abstract class StatInfoVM<T extends IStatBaseRes> {
  _loading = false;
  error: Unset<string> = undefined;
  stat: Unset<T> = undefined;

  get statStrict(): T {
    if (!this.stat) {
      throw new Error('Stat is not defined');
    }
    return this.stat;
  }

  get isLoading() {
    return this._loading;
  }
  get isError() {
    return !!this.error;
  }

  get currentDate() {
    if (!this.stat?.currentDate) {
      return undefined;
    }
    return DateUtil.getDateObj(this.stat.currentDate);
  }
  get currentDateStrict() {
    if (!this.currentDate) {
      return DateUtil.getDateObj();
    }
    return this.currentDate;
  }
  get currentDateText() {
    if (!this.currentDate) {
      return undefined;
    }
    return DateUtil.dateObjToString(this.currentDate);
  }

  private _processing = false;

  constructor() {
    makeObservable<StatInfoVM<T>, '_loading'>(this, {
      _loading: observable,
      error: observable,
      stat: observable,
      statStrict: computed,
      isLoading: computed,
      isError: computed,
      currentDate: computed,
      currentDateStrict: computed,
      currentDateText: computed,
      load: action.bound,
      refetch: action.bound,
      clear: action.bound,
    });
  }

  async load() {
    if (this._loading || this.stat) {
      return;
    }
    this._loading = true;
    this.error = undefined;
    this.stat = undefined;

    try {
      const res = await this.getLoader();
      runInAction(() => {
        this.stat = res;
        this._loading = false;
      });
    } catch (err) {
      runInAction(() => {
        this.error = (err as IResponseError).message;
        this._loading = false;
      });
    }
  }

  async refetch() {
    if (this._processing) {
      return;
    }
    this._processing = true;
    try {
      const res = await this.getLoader();
      runInAction(() => {
        this.stat = res;
      });
    } catch (err) {
      console.log('unable to refetch stat info');
    }
    this._processing = false;
  }

  clear() {
    this._processing = false;
    this._loading = false;
    this.error = undefined;
    this.stat = undefined;
  }

  protected abstract getLoader(): Promise<T>;
}
