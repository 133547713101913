import { FC, ReactNode } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useSessionMainPageRoutePure } from 'entities/session';

interface IProtectedRouteProps {
  authenticated: boolean;
  isAdmin: boolean;
  isCoach: boolean;
  children?: ReactNode;
}
export const ProtectedPublicRoute: FC<IProtectedRouteProps> = ({
  authenticated,
  isAdmin,
  isCoach,
  children,
}) => {
  if (!authenticated) {
    return <>{children || <Outlet />}</>;
  }
  return <Navigate to={useSessionMainPageRoutePure({ authenticated, isAdmin, isCoach })} />;
};
