import { CancelToken } from 'axios';

import { IUserSearchParams } from 'features/user/user-list/api';

import { baseApi } from 'shared/api/axios';
import { IObjectPageDTO } from 'shared/api/common/payload';
import { DEFAULT_PAGE_SIZE } from 'shared/config/const';

import { ICurrentUserResponse } from '../payload';
import { ISimpleUserResponse } from '../payload/simple-user-response';

export function userService_getCurrentUser() {
  return baseApi.getWrap<ICurrentUserResponse>('/current', { service: 'user' });
}

export function userService_getUserList({
  page = 1,
  params,
  limit = DEFAULT_PAGE_SIZE,
  cancelToken,
}: {
  page?: number;
  params: IUserSearchParams;
  limit?: number;
  cancelToken?: CancelToken;
}) {
  return baseApi.postWrap<IObjectPageDTO<ISimpleUserResponse>>('', params, {
    service: 'user',
    params: { page, limit },
    cancelToken,
  });
}
