import { observer } from 'mobx-react-lite';
import { FC, Suspense, lazy } from 'react';

import { UserProfileEditor } from 'features/user/user-profile-editor';

import { Content, Modal, Preloader } from 'shared/ui/layout';

import { UserHeaderProfile } from '../user-header-profile';

import { IUseUserHeaderInfoBodyArgs, useUserHeaderInfoBody } from './user-header-info-body.hook';

const StudioScheduleEditor = lazy(() => import('features/schedule/studio-schedule-editor'));

interface IUserHeaderInfoBodyProps extends IUseUserHeaderInfoBodyArgs {
  visible: boolean;
}
const UserHeaderInfoBodyComponent: FC<IUserHeaderInfoBodyProps> = ({ visible, ...args }) => {
  const {
    mode,
    onBack,
    onLogout,
    onSaveProfile,
    onSaveSchedule,
    onShowScheduleEditor,
    onShowProfileEditor,
  } = useUserHeaderInfoBody(args);
  let body: JSX.Element;
  if (mode === 'view') {
    body = (
      <UserHeaderProfile
        onEdit={onShowProfileEditor}
        onLogout={onLogout}
        onEditSchedule={onShowScheduleEditor}
      />
    );
  } else if (mode === 'edit-schedule') {
    body = (
      <Suspense fallback={<Preloader />}>
        <StudioScheduleEditor onSave={onSaveSchedule} />
      </Suspense>
    );
  } else {
    body = (
      <Content type='modal-form'>
        <UserProfileEditor onSave={onSaveProfile} />
      </Content>
    );
  }

  return (
    <Modal visible={visible} onClose={onBack} noExternal={mode !== 'view'} contentColor='white'>
      {body}
    </Modal>
  );
};

export const UserHeaderInfoBody = observer(UserHeaderInfoBodyComponent);
