import { CSSProperties, useEffect, useState } from 'react';

import { IFileDto } from 'entities/file/api';

export interface IUseAvatarArgs {
  size?: number;
  file?: IFileDto;
}

function getImagePath(image: IFileDto, size: number) {
  const { path, name, extension } = image;
  return `/content/${path}/${name}${size > 36 ? '_lg' : '_sm'}.${extension}`;
}

export function useAvatar({ size = 24, file }: IUseAvatarArgs) {
  const [currentImage, setCurrentImage] = useState<Unset<string>>();
  const style: CSSProperties = {
    width: size,
    height: size,
    minWidth: size,
  };
  useEffect(() => {
    if (!file) {
      setCurrentImage(undefined);
    } else {
      setCurrentImage(file.blurImage);
      const src = getImagePath(file, size);
      const loadingImage = new Image();
      loadingImage.src = src;
      loadingImage.onload = () => {
        setCurrentImage(src);
      };
    }
  }, [file, size]);

  return { style, image: currentImage };
}
