import { baseApi } from 'shared/api/axios';

import { TrainingSearchStatusEnum } from '../enum/training-search-status-enum';
import { ICalendarDayRes } from '../payload/calendar-day';

export function getCalendarByPeriodFull(
  from: string,
  to: string,
  status?: TrainingSearchStatusEnum,
) {
  return baseApi.get<ICalendarDayRes[]>(`/calendar/by-period/from/${from}/to/${to}/full`, {
    service: 'training',
    params: { status },
  });
}
export function getCalendarByPeriodFullWrap(
  from: string,
  to: string,
  status?: TrainingSearchStatusEnum,
) {
  return baseApi.getWrap<ICalendarDayRes[]>(`/calendar/by-period/from/${from}/to/${to}/full`, {
    service: 'training',
    params: { status },
  });
}
