import { baseApi } from 'shared/api/axios';

import { ICalendarDayCoachRes } from '../payload/calendar-day-coach-res';

export function getCoachSlotsByDate(date: string) {
  return baseApi.get<ICalendarDayCoachRes>('/by-day/coach', {
    params: { date },
    service: 'training',
  });
}
