import { useCallback, useState } from 'react';

import { sexTextMap } from 'entities/user/helpers';

import { UserSexEnumType } from 'shared/api/auth';

export interface IUserSexEditorArgs {
  initial?: UserSexEnumType;
  onChange: (sex: UserSexEnumType) => void;
}
export type UserSexOptionType = { value: UserSexEnumType; label: string };
export const userSexOptions: UserSexOptionType[] = [
  { value: 'female', label: sexTextMap.female },
  { value: 'male', label: sexTextMap.male },
];
export function useUserSexEditor({ initial = 'female', onChange }: IUserSexEditorArgs) {
  const [sex, setSex] = useState(initial);

  const handleChangeSex = useCallback(
    ({ value }: UserSexOptionType) => {
      setSex(value);
      onChange(value);
    },
    [onChange],
  );

  return {
    sex,
    onSexChange: handleChangeSex,
  };
}
