import { useQuery } from 'react-query';

import { getAbonementPrices } from '../api/service';

export function useAbonementPrice() {
  return useQuery(['abonement-prices'], getAbonementPrices, {
    cacheTime: Infinity,
    staleTime: Infinity,
  });
}
