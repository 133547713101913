import axios from 'axios';

export interface IResponseError<T = unknown> {
  code: number;
  message: string;
  details?: T;
}

export class SsError<T = unknown> extends Error implements IResponseError<T> {
  code: number;
  message: string;
  details: T | undefined = undefined;
  constructor(err: unknown, defaultMessage = 'Произошла ошибка') {
    super();
    if (axios.isAxiosError(err) && err.response) {
      if (err.response.status === 502) {
        this.code = 502;
        this.message = 'Сервер не отвечает';
      } else {
        const { code, message, details } = (err.response?.data as IResponseError<T>) || {};
        this.code = code || 500;
        this.message = message || defaultMessage;
        this.details = details;
      }
    } else {
      this.code = 500;
      this.message = defaultMessage;
    }
  }
}
