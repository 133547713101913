import { ICalendarDaySimpleInfoRes } from 'entities/training';

import { baseApi } from 'shared/api/axios';

export function getCalendarByPeriod(from: string, to: string, clientId?: number) {
  return baseApi.get<ICalendarDaySimpleInfoRes[]>(`/calendar/by-period/from/${from}/to/${to}`, {
    service: 'training',
    params: { clientId },
  });
}
