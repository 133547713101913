import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { countToStringWithCounter } from 'shared/helpers/component-helpers';
import { DateUtil } from 'shared/helpers/date';
import { IconBirthday, IconBirthdayUnknown, IconFire } from 'shared/icons';
import { Text } from 'shared/ui/layout';

import { useUserHeaderClientInfo } from './user-header-client-info.hook';

import styles from './user-client-info.module.scss';

const UserHeaderClientInfoComponent: FC = () => {
  const { birthday, duration, finishedLessonsCount } = useUserHeaderClientInfo();
  return (
    <div className={styles.container}>
      <div className={styles.card}>
        <div className={styles['card__icon-container']}>
          <img src={IconFire} width={100} alt='' className={styles.card__icon} />
        </div>
        <div>
          <Text type='unset' className={styles.card__title}>
            {finishedLessonsCount === null || typeof finishedLessonsCount === 'undefined' ? (
              <>&nbsp;</>
            ) : (
              <>{countToStringWithCounter(finishedLessonsCount, 'час', 'часа', 'часов')}</>
            )}
          </Text>
          <Text type='unset' className={styles.card__description}>
            за {duration}
          </Text>
        </div>
      </div>
      <div className={styles.card}>
        <div className={styles['card__icon-container']}>
          <img
            src={birthday ? IconBirthday : IconBirthdayUnknown}
            width={100}
            alt=''
            className={styles.card__icon}
          />
        </div>
        <div>
          <Text type='unset' className={styles.card__title}>
            {birthday ? DateUtil.formatDate(birthday, 'DD MMM') : '-'}
          </Text>
          <Text type='unset' className={styles.card__description}>
            день рождения
          </Text>
        </div>
      </div>
    </div>
  );
};

export const UserHeaderClientInfo = observer(UserHeaderClientInfoComponent);
