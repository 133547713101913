import { AppRoutes } from 'pages';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';

import './app.scss';
import { withQueryClient, withSessionCheck } from './providers';

const App = () => {
  return <AppRoutes />;
};

export default withQueryClient(withSessionCheck(App));
