import { useMemo } from 'react';

import { IUseTransitionElementBodyArgs, useTransitionElementBody } from '../../transition-element';

import { IModalBodyContext } from './modal-body.context';

export function useModalBody({ onClose, noExternal }: IUseTransitionElementBodyArgs) {
  const { wrapperRef, contentRef } = useTransitionElementBody({ onClose, noExternal });

  const value: IModalBodyContext = useMemo(() => {
    return { wrapper: wrapperRef };
  }, [wrapperRef]);

  return {
    wrapperRef,
    contentRef,
    value,
  };
}
