/* eslint-disable @typescript-eslint/no-unused-vars */
import { PropsWithChildren, forwardRef, memo } from 'react';

import { Button, IButtonProps } from '../button';

import { IUseLinkButtonArgs, useLinkButton } from './link-button.hook';

type ILinkButtonProps = IUseLinkButtonArgs & Omit<IButtonProps, 'onClick'>;

export const LinkButton = memo(
  forwardRef<HTMLButtonElement, PropsWithChildren<ILinkButtonProps>>(function LinkButton(
    args,
    ref,
  ) {
    const { onClick } = useLinkButton(args);
    const { to, external, blank, ref: rr, ...rest } = args;
    return <Button ref={ref} onClick={onClick} {...rest} />;
  }),
);
