import clsx from 'clsx';
import { FC, HTMLAttributes, PropsWithChildren } from 'react';

import styles from './text.module.scss';

interface ITextProps extends HTMLAttributes<unknown> {
  type?:
    | 'supertitle'
    | 'title'
    | 'title-strict'
    | 'main'
    | 'block-title'
    | 'block-subtitle'
    | 'element-title'
    | 'small-text'
    | 'small-mobile-text'
    | 'tiny-text'
    | 'unset';
  text?: 'main' | 'dark' | 'light' | 'error' | 'opacity-30' | 'unset';
  weight?: 'bold' | 'unset';
  lineHeight?: 'normal' | 'unset';
  align?: 'center' | 'left' | 'center-on-mobile' | 'left-on-mobile' | 'unset';
  element?: 'p' | 'span' | 'h1' | 'div';
  space?: 'nowrap' | 'pre' | 'default';
  dotOverflow?: boolean;
}

export const Text: FC<PropsWithChildren<ITextProps>> = ({
  type = 'main',
  text = 'unset',
  align = 'unset',
  weight = 'unset',
  lineHeight = 'unset',
  element: Element = 'div',
  children,
  className,
  space = 'default',
  dotOverflow: oneLine,
  ...otherProps
}) => {
  return (
    <Element
      {...otherProps}
      className={clsx(
        type !== 'unset' && styles[`type-${type}`],
        text !== 'unset' && styles[`text-${text}`],
        weight !== 'unset' && styles[`weight-${weight}`],
        styles[`align-${align}`],
        space !== 'default' && styles[`space-${space}`],
        oneLine && 'one-line-cutted-text',
        lineHeight !== 'unset' && styles[`line-height-${lineHeight}`],
        className,
      )}
    >
      {children}
    </Element>
  );
};
