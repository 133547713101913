import clsx from 'clsx';
import ru from 'date-fns/locale/ru';
import { CSSProperties, FC, useId, useRef } from 'react';
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker';
import { FieldError } from 'react-hook-form';

import { ControlLabel } from '../control-label';

import styles from './date-input.module.scss';

interface IDateInputProps extends ReactDatePickerProps {
  dateInputClassName?: string;
  dateInputStyle?: CSSProperties;
  error?: FieldError | string;
  label?: string;
}

export const DateInput: FC<IDateInputProps> = ({
  id,
  error,
  label,
  dateInputClassName,
  dateInputStyle,
  className,
  dateFormat = 'dd.MM.yyyy',
  ...rest
}) => {
  const inputId = useId();
  const idRef = useRef(id || inputId);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const additionalProps: any = {
    wrapperClassName: styles.wrapper,
  };
  return (
    <div className={dateInputClassName} style={dateInputStyle}>
      <ControlLabel id={idRef.current} label={label} error={error} />
      <ReactDatePicker
        id={idRef.current}
        locale={ru}
        dateFormat={dateFormat}
        containerClassName='container'
        className={clsx(styles.control, error && styles.control_error, className)}
        {...rest}
        {...additionalProps}
      />
    </div>
  );
};
