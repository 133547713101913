import { IStatAdminRes } from 'entities/stat/api/payload/stat-admin-res';
import { getAdminStat } from 'entities/stat/api/service/get-admin-stat';

import { StatInfoVM } from './stat-info.vm';

export class StatAdminInfoVM extends StatInfoVM<IStatAdminRes> {
  protected getLoader(): Promise<IStatAdminRes> {
    return getAdminStat();
  }
}
