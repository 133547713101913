import { useCallback, useRef, useState } from 'react';

import { IFileDto } from 'entities/file';

import { showErrorToaster } from 'shared/helpers/toaster';

import { uploadFile } from '../api';

export function useFileUploader(onAccept: (file: IFileDto) => void) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [uploading, setUploading] = useState(false);

  const handleClick = (e: React.ChangeEvent<HTMLInputElement>): void | Promise<void> => {
    if (uploading) {
      return;
    }
    const file = e.target.files ? e.target.files[0] : undefined;
    if (!file) {
      return;
    }
    setUploading(true);
    uploadFile(file)
      .then((data) => {
        onAccept(data);
      })
      .catch((err) => {
        showErrorToaster(err);
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const handleParentClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  }, []);

  return {
    onClick: handleParentClick,
    onChange: handleClick,
    uploading,
    inputRef,
  };
}
