import { useEffect, useState } from 'react';

import { useStat } from 'entities/stat';

export interface IUseAbonementIconArgs {
  type: string;
}
export function useAbonementIcon({ type }: IUseAbonementIconArgs) {
  const { stat: statData } = useStat().currentStat;
  const [src, setSrc] = useState<Unset<string>>();
  useEffect(() => {
    const abonementType = statData?.abonements ? statData.abonements[type] : undefined;
    if (!abonementType) {
      return;
    }
    const abonementImageSrc = abonementType.imageUri;
    if (!abonementImageSrc) {
      return;
    }
    const loadingImage = new Image();
    loadingImage.src = abonementImageSrc;
    loadingImage.onload = () => {
      setSrc(abonementImageSrc);
    };
  }, [type, statData]);
  return {
    src,
  };
}
