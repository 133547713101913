import { baseApi } from 'shared/api/axios';

import { ICalendarDayCoachRes } from '../payload/calendar-day-coach-res';

export function getCalendarByPeriodCoach(from: string, to: string) {
  return baseApi.get<ICalendarDayCoachRes[]>(`/calendar/by-period/from/${from}/to/${to}/coach`, {
    service: 'training',
  });
}
export function getCalendarByPeriodCoachWrap(from: string, to: string) {
  return baseApi.getWrap<ICalendarDayCoachRes[]>(
    `/calendar/by-period/from/${from}/to/${to}/coach`,
    {
      service: 'training',
    },
  );
}
