import { useMemo } from 'react';

import { IAbonementInfoRes } from 'entities/abonement/api/payload';
import { LESSON_PLURAL_VALUES } from 'entities/training';

import { countToStringWithCounter } from 'shared/helpers/component-helpers';
import { DateUtil } from 'shared/helpers/date';

export type IUseUserCurrentAbonementArgs = Pick<
  IAbonementInfoRes,
  'type' | 'status' | 'createdAt' | 'activatedAt' | 'expiredAt' | 'lessonCount' | 'totalLessonCount'
>;

export function useUserCurrentAbonement({
  type,
  status,
  createdAt,
  activatedAt,
  expiredAt,
  lessonCount,
  totalLessonCount,
}: IUseUserCurrentAbonementArgs) {
  const expiredText = useMemo(() => {
    if (!expiredAt) {
      return undefined;
    }
    const expiredDate = DateUtil.getDateObj(expiredAt)
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    const now = DateUtil.getDateObj()
      .set('hour', 0)
      .set('minute', 0)
      .set('second', 0)
      .set('millisecond', 0);
    const expiredMonth = expiredDate.diff(now, 'month');
    if (expiredMonth > 0) {
      return `${expiredMonth} мес`;
    }
    const expiredDay = expiredDate.diff(now, 'day');
    return countToStringWithCounter(expiredDay > 0 ? expiredDay : 0, 'день', 'дня', 'дней');
  }, [expiredAt]);
  const statusText = useMemo(() => {
    if (status === 'expired') {
      return 'Срок действия закончился';
    }
    if (status === 'frozen') {
      return 'Абонемент заморожен';
    }
    if (lessonCount === 0) {
      return 'Занятия закончились';
    }
    return `${lessonCount} из 
    ${countToStringWithCounter(totalLessonCount, ...LESSON_PLURAL_VALUES)} осталось`;
  }, [status, lessonCount, totalLessonCount]);

  return {
    type,
    status,
    createdAt,
    activatedAt,
    expiredAt,
    expiredText,
    statusText,
  };
}
