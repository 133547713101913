import { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

export function useDesktopLayoutDetect(
  { from }: { from: 'tablet' | 'desktop' } = { from: 'tablet' },
) {
  const isDesktopLayout = useMediaQuery({
    query: `(min-width: ${from === 'tablet' ? 600 : 1200}px)`,
  });
  return isDesktopLayout;
}

export function useMobileLayoutDetect({ to }: { to: 'tablet' | 'desktop' } = { to: 'tablet' }) {
  const isMobileLayout = useMediaQuery({
    query: `(max-width: ${to === 'tablet' ? 599 : 1199}px)`,
  });
  return isMobileLayout;
}

export function useScreenLayoutDetect() {
  const isLargeDesktopLayout = useMediaQuery({ query: `(min-width: 1200px)` });
  const isTabletLayout = useMediaQuery({ query: `(min-width: 600px) and (max-width: 1199px)` });
  const isMobileLayout = useMediaQuery({ query: `(max-width: 599px)` });

  const result = useMemo(
    () => ({
      isDesktopLayout: isLargeDesktopLayout || isTabletLayout,
      isMobileLayout,
      isTabletLayout,
      isLargeDesktopLayout,
      isMobileOrTabletLayout: isMobileLayout || isTabletLayout,
    }),
    [isLargeDesktopLayout, isMobileLayout, isTabletLayout],
  );

  return result;
}
