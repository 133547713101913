import { action, computed, makeObservable, observable, runInAction } from 'mobx';

import { StatStore } from 'entities/stat';

import { ICurrentUserResponse, userService_getCurrentUser } from 'shared/api/user';
import { SS_COOKIE_ACCESS_TOKEN, getCookie, removeCookie } from 'shared/helpers/cookie';
import { PushClient } from 'shared/helpers/push-client';

export class SessionVM {
  initialized = false;

  session: Nullable<ICurrentUserResponse> = null;

  private _processing = false;

  get authenticated() {
    return !!this.session;
  }

  get sessionInfo(): ICurrentUserResponse {
    if (!this.session) {
      throw Error('Нет пользователя');
    }
    return this.session;
  }

  get userId(): number {
    if (!this.session) {
      throw Error('Нет пользователя');
    }
    return this.session.id;
  }

  get isAdmin() {
    return !!(this.session?.role === 'ROLE_ADMIN');
  }
  get isCoach() {
    return !!(this.session?.role === 'ROLE_TRAINER');
  }
  get isClient() {
    return !!(this.session?.role === 'ROLE_USER');
  }

  constructor() {
    makeObservable(this, {
      initialized: observable,
      session: observable,
      authenticated: computed,
      isAdmin: computed,
      isCoach: computed,
      init: action.bound,
      reloadSession: action.bound,
      clearSession: action.bound,
    });
  }

  async init() {
    if (this.initialized) {
      return;
    }
    if (!getCookie(SS_COOKIE_ACCESS_TOKEN)) {
      this.initialized = true;
      StatStore.load('base');
      return;
    }
    this.reloadSession();
  }

  async reloadSession() {
    if (this._processing) {
      return;
    }
    this._processing = true;
    const res = await userService_getCurrentUser();
    runInAction(() => {
      if (res.success) {
        this.session = res.data;
        PushClient.open(res.data.broadcast);
        if (this.isCoach) {
          StatStore.load('base');
        } else {
          StatStore.load(this.isAdmin ? 'admin' : 'client');
        }
      } else {
        this.clearSession();
      }
      if (!this.initialized) {
        this.initialized = true;
      }
      this._processing = false;
    });
  }

  async clearSession() {
    this.session = null;
    StatStore.clearCurrentStat();
    StatStore.load('base');
    PushClient.close();
    removeCookie(SS_COOKIE_ACCESS_TOKEN);
  }
}

export const SessionStore = new SessionVM();
