import { action, makeObservable, observable } from 'mobx';

import { PushClient } from 'shared/helpers/push-client';

import { IStatBaseRes } from '../api/payload/stat-base-res';

import { StatAdminInfoVM } from './info/stat-admin-info.vm';
import { StatBaseInfoVM } from './info/stat-base-info.vm';
import { StatClientInfoVM } from './info/stat-client-info.vm';
import { StatInfoVM } from './info/stat-info.vm';

type StatType = 'base' | 'admin' | 'client';

class StatVM {
  stat = new StatBaseInfoVM();
  clientStat = new StatClientInfoVM();
  adminStat = new StatAdminInfoVM();

  private _type: StatType = 'base';

  get currentStat(): StatInfoVM<IStatBaseRes> {
    return this._getStat(this._type);
  }

  constructor() {
    makeObservable<StatVM, '_type'>(this, {
      _type: observable,
      load: action.bound,
      clearCurrentStat: action.bound,
    });
    this.refetchCurrentStat = this.refetchCurrentStat.bind(this);
    PushClient.registerCallback('all', this.refetchCurrentStat);
  }

  load(type: StatType) {
    this._type = type;
    this._getStat(type).load();
  }
  clearCurrentStat() {
    if (this._type !== 'base') {
      this._getStat(this._type).clear();
      this._type = 'base';
    }
  }
  refetchCurrentStat() {
    this._getStat(this._type).refetch();
  }

  private _getStat(type: StatType) {
    if (type === 'base') {
      return this.stat;
    }
    if (type === 'client') {
      return this.clientStat;
    }
    return this.adminStat;
  }
}

export const StatStore = new StatVM();
