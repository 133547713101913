import { useEffect, useRef, useState } from 'react';
import useTransition from 'react-transition-state';

import { getUuid } from '../component-helpers';

const modalsSet = new Set<string>();

export const modalContainerId = 'modal-root';

function checkSet() {
  if (modalsSet.size > 0) {
    document.body.classList.add('with-modal');
    const scrollDiv = document.createElement('div');
    scrollDiv.className = 'scrollbar-measure';
    document.body.appendChild(scrollDiv);
    const scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
    document.body.removeChild(scrollDiv);
    document.documentElement.style.setProperty('--scroll-padding', `${scrollbarWidth}px`);
  } else {
    document.body.classList.remove('with-modal');
  }
}

function addModal(uuid: string) {
  modalsSet.add(uuid);
  checkSet();
}
function deleteModal(uuid: string) {
  modalsSet.delete(uuid);
  checkSet();
}

export function useModal(visible: boolean) {
  const uuidRef = useRef(getUuid());
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);
  const [{ status, isMounted }, toggle] = useTransition({
    mountOnEnter: true,
    preEnter: true,
    timeout: 200,
    unmountOnExit: true,
  });

  useEffect(() => {
    toggle(visible);
  }, [visible]);

  useEffect(() => {
    if (isMounted) {
      const modalRoot = document.getElementById('modal-root');
      if (modalRoot) {
        const element = document.createElement('div');
        modalRoot.appendChild(element);
        elementRef.current = element;
        setMounted(true);
      }
      return () => {
        if (elementRef.current !== null) {
          const parent = elementRef.current.parentElement;
          if (parent) {
            parent.removeChild(elementRef.current);
          }
          elementRef.current = null;
        }
        setMounted(false);
      };
    }
    return () => {
      elementRef.current = null;
      setMounted(false);
    };
  }, [isMounted]);

  useEffect(() => {
    if (isMounted) {
      const uuid = uuidRef.current;
      addModal(uuid);
      return () => {
        deleteModal(uuid);
      };
    }
  }, [isMounted]);
  return { mounted, elementRef, status };
}
