import clsx from 'clsx';
import { FC, PropsWithChildren } from 'react';

import { LayoutContextProvider } from 'shared/context';
import { modalContainerId } from 'shared/helpers/modal';

import { Dialog } from '../dialog';
import { Header } from '../header';
import { LayoutProcessing } from '../layout-processing';

import { LAYOUT_HEADER_PORTAL_CONTAINER } from './layout.const';
import { useLayout } from './layout.hook';

import styles from './layout.module.scss';

export const Layout: FC<PropsWithChildren> = ({ children }) => {
  const { background, className, allowBack, mobileScrolled, onBack, setProps } = useLayout();
  return (
    <div className={clsx(background !== 'unset' && styles[`wrapper-${background}`])}>
      {background === 'light' && <div className={styles.background} />}
      <div className={clsx(styles.container, className)}>
        <div id={modalContainerId} />
        <Dialog />
        <Header
          pageHeader
          onBack={allowBack ? onBack : undefined}
          mobileMode={mobileScrolled ? 'dark' : 'default'}
          actionContainerId={LAYOUT_HEADER_PORTAL_CONTAINER}
        />
        <LayoutContextProvider value={setProps}>{children}</LayoutContextProvider>
        <LayoutProcessing />
      </div>
    </div>
  );
};
