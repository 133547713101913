import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { Avatar } from 'entities/file';
import { SessionStore } from 'entities/session/view-model';
import clsx from 'clsx';

interface IUserInfoProps {
  onClick?: () => void;
  className?: string;
}
const UserInfoComponent: FC<IUserInfoProps> = ({ onClick, className }) => {
  const { session } = SessionStore;

  if (!session) {
    return null;
  }
  const { avatar } = session;
  return (
    <div>
      <div className={clsx('float-right', className)} onClick={onClick}>
        <Avatar file={avatar} size={36} />
      </div>
    </div>
  );
};

export const UserInfo = observer(UserInfoComponent);
