import { observer } from 'mobx-react-lite';
import { FC, Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import {
  ProtectedAdminRoute,
  ProtectedCoachRoute,
  ProtectedPublicRoute,
  ProtectedUserRoute,
} from 'features/session';

import { SessionStore } from 'entities/session';

import { Preloader } from 'shared/ui/layout';
import { Layout } from 'shared/ui/layout/layout';

import { NotFound } from './not-found';

const MainPage = lazy(() => import('./main-page'));
const UserProfilePage = lazy(() => import('./user-profile-page'));
const CoachProfilePage = lazy(() => import('./coach/coach-profile-page'));
const CoachSlotsPage = lazy(() => import('./coach/coach-slots-page'));
const AdminMainPage = lazy(() => import('./admin/admin-main-page'));
const AdminCoachesPage = lazy(() => import('./admin/admin-coaches-page'));
const AdminClientsPage = lazy(() => import('./admin/admin-clients-page'));
const AdminSlotsPage = lazy(() => import('./admin/admin-slots-page'));
const InfoPage = lazy(() => import('./ifno-page'));
const RecoverPasswordPage = lazy(() => import('./recover-password-page'));

const AppRoutesComponent: FC = () => {
  const { authenticated, isAdmin, isCoach } = SessionStore;
  return (
    <BrowserRouter>
      <Layout>
        <Suspense fallback={<Preloader />}>
          <Routes>
            <Route
              index
              element={
                <ProtectedPublicRoute
                  authenticated={authenticated}
                  isAdmin={isAdmin}
                  isCoach={isCoach}
                >
                  <MainPage />
                </ProtectedPublicRoute>
              }
            />
            <Route
              path='profile'
              element={
                <ProtectedUserRoute
                  authenticated={authenticated}
                  isAdmin={isAdmin}
                  isCoach={isCoach}
                >
                  <UserProfilePage />
                </ProtectedUserRoute>
              }
            />
            <Route path='coach' element={<ProtectedCoachRoute />}>
              <Route path='profile' element={<CoachProfilePage />} />
              <Route path='slots' element={<CoachSlotsPage />} />
              <Route path='*' element={<NotFound />} />
            </Route>
            <Route path='admin' element={<ProtectedAdminRoute isAdmin={isAdmin} />}>
              <Route path='profile' element={<AdminMainPage />} />
              <Route path='coaches' element={<AdminCoachesPage />} />
              <Route path='clients' element={<AdminClientsPage />} />
              <Route path='slots' element={<AdminSlotsPage />} />
              <Route path='*' element={<NotFound />} />
            </Route>
            <Route path='payment' element={<InfoPage />} />
            <Route path='confirm-email' element={<InfoPage />} />
            <Route path='recover-password' element={<RecoverPasswordPage />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Suspense>
      </Layout>
      <ToastContainer />
    </BrowserRouter>
  );
};

export const AppRoutes = observer(AppRoutesComponent);
