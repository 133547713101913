import { observer } from 'mobx-react-lite';
import { FC } from 'react';

import { Avatar } from 'entities/file';
import { useSession } from 'entities/session';

import { DateUtil } from 'shared/helpers/date';
import { Button } from 'shared/ui/controls';
import { Content, Text } from 'shared/ui/layout';

import { UserHeaderClientInfo } from '../user-header-client-info';

import styles from './user-header-profile.module.scss';

interface IUserHeaderProfileProps {
  onLogout: () => void;
  onEdit: () => void;
  onEditSchedule: () => void;
}
const UserHeaderProfileComponent: FC<IUserHeaderProfileProps> = ({
  onEdit,
  onEditSchedule,
  onLogout,
}) => {
  const { isClient, isAdmin, sessionInfo } = useSession();
  const { registeredAt, avatar, name } = sessionInfo;
  return (
    <Content type='modal-form-wide' className={styles.container}>
      <div className={styles['avatar-container']}>
        <div className={styles['avatar-container__background']} />
        <Avatar size={230} radius='large' file={avatar} className={styles.avatar} />
      </div>
      <div className={styles['user-info']}>
        <Text type='block-title' align='center'>
          {name}
        </Text>
        <Text text='opacity-30' align='center'>
          в Stretching Space с {DateUtil.formatDate(registeredAt, 'DD MMM YYYY')}
        </Text>
      </div>
      <div className={styles['info-container']}>
        {isClient && <UserHeaderClientInfo />}
        <Content padding='small'>
          <Button buttonType='gray' font='default' className='mb-2' onClick={onEdit}>
            Редактировать
          </Button>
          {isAdmin && (
            <Button buttonType='gray' font='default' className='mb-2' onClick={onEditSchedule}>
              Настроить график студии
            </Button>
          )}
          <Button buttonType='dark' onClick={onLogout}>
            Выйти из учетной записи
          </Button>
        </Content>
      </div>
    </Content>
  );
};

export const UserHeaderProfile = observer(UserHeaderProfileComponent);
