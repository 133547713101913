import clsx from 'clsx';
import { FC } from 'react';

import { DEFAULT_ADDRESS, YANDEX_MAP_ADDRESS_URL } from 'shared/config/env';
import { IconArrowRight } from 'shared/icons';
import { LinkButton } from 'shared/ui/controls';
import { Text } from 'shared/ui/layout';

import { IUseSlotBaseInfoArgs, useSlotBaseInfo } from './slot-base-info.hook';

import styles from './slot-base-info.module.scss';

export interface ISlotBaseInfoProps extends IUseSlotBaseInfoArgs {
  lessonType?: string;
  className?: string;
}

export const SlotBaseInfo: FC<ISlotBaseInfoProps> = ({ lessonType = '', className, ...args }) => {
  const { dateTime } = useSlotBaseInfo(args);
  return (
    <>
      <Text type='supertitle'>Занятие</Text>
      <Text type='title' className={styles.title}>
        {dateTime}
      </Text>
      <table className={clsx(styles.info, className)}>
        <tbody>
          <tr>
            <td>Тип</td>
            <td>{lessonType}</td>
          </tr>
          <tr>
            <td>Адрес</td>
            <td>
              <Text className='mb-2'>{DEFAULT_ADDRESS}</Text>
              <LinkButton
                to={YANDEX_MAP_ADDRESS_URL}
                external
                blank
                height='thin'
                contentType='center'
                radius='small'
                padding='small'
                buttonType='gray'
                font='default'
                width='content'
                text='medium'
              >
                На карте
                <img src={IconArrowRight} alt='' />
              </LinkButton>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};
