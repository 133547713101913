import { useEffect, useRef } from 'react';

export interface IUseTransitionElementBodyArgs {
  onClose: () => void;
  noExternal?: boolean;
}

export function useTransitionElementBody({ onClose, noExternal }: IUseTransitionElementBodyArgs) {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const insideContentRef = useRef(false);

  useEffect(() => {
    if (!wrapperRef.current || !contentRef.current) {
      return;
    }
    const wrapper = wrapperRef.current;
    const content = contentRef.current;
    const externalMouseDown = (e: globalThis.MouseEvent) => {
      const target = e.target as Node;
      insideContentRef.current = content.contains(target) || target === wrapper;
    };
    const externalMouseUp = (e: globalThis.MouseEvent) => {
      if (insideContentRef.current) {
        return;
      }
      if (content.contains(e.target as Node)) {
        return;
      }
      if (!noExternal) {
        onClose();
      }
    };
    wrapper.addEventListener('mousedown', externalMouseDown, false);
    wrapper.addEventListener('mouseup', externalMouseUp, false);
    return () => {
      wrapper.removeEventListener('mousedown', externalMouseDown, false);
      wrapper.removeEventListener('mouseup', externalMouseUp, false);
    };
  });

  return { wrapperRef, contentRef };
}
