import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false } },
});

export const withQueryClient = (component: () => React.ReactNode) => {
  return function withQueryClient() {
    return <QueryClientProvider client={queryClient}>{component()}</QueryClientProvider>;
  };
};
