import { useMemo } from 'react';

import { DateType, DateUtil } from 'shared/helpers/date';

export interface IUseSlotBaseInfoArgs {
  date?: DateType;
  time?: number;
}
export function useSlotBaseInfo({ date, time }: IUseSlotBaseInfoArgs) {
  const dateTime = useMemo(() => DateUtil.dateObjAndTimeToString({ date, time }), [date, time]);

  return {
    dateTime,
  };
}
