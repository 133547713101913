import { Client } from '@stomp/stompjs';

import { EventTypeEnum, IEventRes } from 'entities/event';

import { MAIN_DOMAIN } from 'shared/config/env';

type EventRegisterType = EventTypeEnum | 'all';
type EventCallbackType = (event: IEventRes) => void;
class PushClientClass {
  private client: Unset<Client>;

  private _hooks: Map<EventRegisterType, Set<EventCallbackType>> = new Map();

  open = (broadcast: string) => {
    const url = `wss://${MAIN_DOMAIN}/stomp`;
    this.client = new Client({
      brokerURL: url,
      reconnectDelay: 5000,
      heartbeatIncoming: 4000,
      heartbeatOutgoing: 4000,
    });
    this.client.onConnect = () => {
      this.client?.subscribe(`/event/${broadcast}`, (message) => {
        this._onGetEvent(message.body);
      });
    };
    this.client.activate();
  };

  close = () => {
    if (this.client) {
      this.client.deactivate();
      this.client = undefined;
    }
  };

  registerCallback = (
    events: EventRegisterType | EventRegisterType[],
    callback: EventCallbackType,
  ) => {
    const evenTypes = Array.isArray(events) ? events : [events];
    evenTypes.forEach((event) => {
      if (!this._hooks.has(event)) {
        this._hooks.set(event, new Set());
      }
      this._hooks.get(event)?.add(callback);
    });
  };

  unregisterCallback = (
    events: EventRegisterType | EventRegisterType[],
    callback: EventCallbackType,
  ) => {
    const evenTypes = Array.isArray(events) ? events : [events];
    evenTypes.forEach((event) => {
      if (this._hooks.has(event)) {
        this._hooks.get(event)?.delete(callback);
      }
    });
  };

  private _onGetEvent = (msg: string) => {
    try {
      const event = JSON.parse(msg) as IEventRes;
      const { eventType } = event;
      const hooks = [
        ...Array.from(this._hooks.get(eventType) || []),
        ...Array.from(this._hooks.get('all') || []),
      ];
      hooks.forEach((cl) => {
        cl(event);
      });
      // eslint-disable-next-line no-empty
    } catch (err) {
      console.log(err);
    }
  };
}

const client = new PushClientClass();
export { client as PushClient };
