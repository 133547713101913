import { useMemo } from 'react';

import {
  getSelectableOptionLabelProperty,
  getSelectableOptionValueProperty,
} from 'shared/helpers/component-helpers';

export interface IUseSelectPopupDefaultControlArgs<T extends SelectableOptionType> {
  placeholder?: string;
  options: T[];
  value?: SelectableValueType<T>;
  valueProperty: AffordableSelectorKey<T>;
  labelProperty: AffordableSelectorKey<T>;
}
export function useSelectPopupDefaultControl<T extends SelectableOptionType>({
  placeholder,
  options,
  value,
  valueProperty,
  labelProperty,
}: IUseSelectPopupDefaultControlArgs<T>) {
  const selectedOptionLabel = useMemo(() => {
    if (!value) {
      return undefined;
    }
    if (typeof value !== 'number' && typeof value !== 'string') {
      return value[labelProperty] as string;
    }
    const selectedValue = getSelectableOptionValueProperty(valueProperty, value);
    if (!selectedValue) {
      return undefined;
    }
    const selectedOption = options.find(
      (option) => getSelectableOptionValueProperty(valueProperty, option) === selectedValue,
    );
    if (!selectedOption) {
      return undefined;
    }
    return getSelectableOptionLabelProperty(labelProperty, selectedOption);
  }, [options, value, valueProperty, labelProperty]);

  const controlValue = useMemo(() => {
    if (typeof selectedOptionLabel === 'undefined') {
      if (placeholder) {
        return placeholder;
      }
      return '';
    }
    return selectedOptionLabel;
  }, [selectedOptionLabel, placeholder]);

  const isPlaceholderViewed = useMemo(() => {
    return typeof selectedOptionLabel === 'undefined' && !!placeholder;
  }, [selectedOptionLabel, placeholder]);

  return {
    controlValue,
    isPlaceholderViewed,
  };
}
