import { SsError } from 'shared/api/axios';

import { showErrorToaster } from '../toaster';

import {
  IRequestProcessingComponent,
  RequestProcessingActionType,
} from './request-processing.type';

class RequestProcessingClass implements IRequestProcessingComponent {
  private _processing = false;
  private _actionSet: Set<RequestProcessingActionType> = new Set();

  get processing(): boolean {
    return this._processing;
  }

  constructor() {
    this.perform = this.perform.bind(this);
  }

  register = (callback: RequestProcessingActionType) => {
    this._actionSet.add(callback);
  };
  unregister = (callback: RequestProcessingActionType) => {
    this._actionSet.delete(callback);
  };

  setProcessing = (processing: boolean, args: { silent?: boolean } = {}) => {
    this._processing = processing;
    const { silent = false } = args;
    if (!silent || processing === false) {
      this._actionSet.forEach((action) => action(processing));
    }
  };

  perform<T>(
    promise: Promise<T>,
    resolve?: (res: T) => void,
    options: {
      error?: 'none' | 'toastify' | ((err: SsError) => void);
      onProcessing?: (processing: boolean) => void;
      args?: { silent?: boolean };
    } = {},
  ) {
    const { args, error = 'toastify', onProcessing } = options;
    if (this.processing) {
      return;
    }
    this.setProcessing(true, args);
    if (onProcessing) {
      onProcessing(true);
    }
    promise
      .then((res) => {
        if (resolve) {
          resolve(res);
        }
      })
      .catch((err) => {
        if (error !== 'none') {
          if (error === 'toastify') {
            showErrorToaster(err);
          } else {
            error(err);
          }
        }
      })
      .finally(() => {
        this.setProcessing(false);
        if (onProcessing) {
          onProcessing(false);
        }
      });
  }
}

const vm = new RequestProcessingClass();
const componentVm = vm as IRequestProcessingComponent;

export function useRequestProcessing() {
  const { processing, setProcessing, perform } = vm;

  return { processing, setProcessing, perform };
}

export { componentVm as RequestProcessingComponent };
