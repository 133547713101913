import { MouseEventHandler } from 'react';
import { useNavigate } from 'react-router-dom';

export type IUseLinkButtonArgs = {
  to: string;
} & ({ external?: never; blank?: never } | { external: true; blank?: true });

export function useLinkButton({ to, external, blank }: IUseLinkButtonArgs) {
  const navigate = useNavigate();
  const handleClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (external) {
      window.open(to, blank ? '_blank' : undefined);
    } else {
      navigate(to);
    }
  };

  return {
    onClick: handleClick,
  };
}
