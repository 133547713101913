import clsx from 'clsx';

import { getSelectableOptionLabelProperty } from 'shared/helpers/component-helpers';

import { IUseSelectPopupOptionArgs, useSelectPopupOption } from './select-popup-option.hook';

import styles from '../select-popup.module.scss';

export interface ISelectPopupOptionProps<T extends SelectableOptionType>
  extends IUseSelectPopupOptionArgs<T> {
  selected: boolean;
  labelProperty: AffordableSelectorKey<T>;
  optionClassName?: string;
}

export function SelectPopupOption<T extends SelectableOptionType>({
  selected,
  labelProperty,
  option,
  optionClassName,
  onSelect,
}: ISelectPopupOptionProps<T>) {
  const { onClick } = useSelectPopupOption({ option, onSelect });
  return (
    <div
      className={clsx(styles.option, selected && styles.option_selected, optionClassName)}
      onClick={onClick}
    >
      {getSelectableOptionLabelProperty(labelProperty, option)}
    </div>
  );
}
