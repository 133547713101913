import clsx from 'clsx';
import { InputHTMLAttributes, memo, useId, useRef } from 'react';
import MaskedInput from 'react-text-mask';

import { ControlLabel } from '../control-label';
import { IInputDefaultProps } from '../input';

import styles from './phone-input.module.scss';

interface IPhoneInputProps extends IInputDefaultProps, InputHTMLAttributes<HTMLInputElement> {}

const mask = [
  '+',
  '7',
  '(',
  /\d/,
  /\d/,
  /\d/,
  ')',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
];

export const PhoneInput = memo(function PhoneInput({
  id,
  error,
  className,
  wrapperClassName,
  wrapperStyle,
  placeholder,
  label,
  ...rest
}: IPhoneInputProps) {
  const inputId = useId();
  const idRef = useRef(id || inputId);

  return (
    <div className={wrapperClassName} style={wrapperStyle}>
      <ControlLabel id={idRef.current} label={label} error={error} />
      <MaskedInput
        mask={mask}
        className={clsx(styles.control, error && styles.control_error, className)}
        placeholder={placeholder || '+7(999)999-99-99'}
        {...rest}
      />
    </div>
  );
});
