import { useCallback, useRef, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IFileDto } from 'entities/file';
import { SessionStore } from 'entities/session';

import { UserSexEnumType } from 'shared/api/auth';
import { IResponseError } from 'shared/api/axios';
import { DateUtil } from 'shared/helpers/date';
import { useRequestProcessing } from 'shared/helpers/request-processing';
import { showErrorToaster } from 'shared/helpers/toaster';

import { IEditUserProfileReq } from '../api/payload/edit-user-profile-req';
import { editProfile } from '../api/service/edit-profile';

import { IUserProfileEditorFields } from './user-profile-editor.fields';

export interface IUseUserProfileEditorArgs {
  onSave: () => void;
}
export function useUserProfileEditor({ onSave }: IUseUserProfileEditorArgs) {
  const { perform } = useRequestProcessing();
  const { sessionInfo } = SessionStore;
  const sexRef = useRef<UserSexEnumType>(sessionInfo.sex);
  const [avatar, setAvatar] = useState<Unset<IFileDto>>(() => sessionInfo.avatar);

  const {
    register,
    control,
    formState: { errors },
    setError,
    handleSubmit,
  } = useForm<IUserProfileEditorFields>({
    values: {
      birthday: sessionInfo.birthday
        ? DateUtil.getDateObj(sessionInfo.birthday).toDate()
        : undefined,
      name: sessionInfo.name,
      phone: sessionInfo.phone,
    },
  });

  const submitHandler: SubmitHandler<IUserProfileEditorFields> = (body) => {
    const requestBody: IEditUserProfileReq = {
      ...body,
      sex: sexRef.current,
      birthday: DateUtil.dateToString(body.birthday as Date) as string,
      avatarId: avatar?.id,
    };

    perform(editProfile(requestBody), onSave, {
      error: (err) => {
        const { message, details } = err as IResponseError<IEditUserProfileReq>;
        if (details) {
          if (details.phone) {
            setError('phone', { type: 'manual', message: details.phone });
          }
          if (details.name) {
            setError('name', { type: 'manual', message: details.name });
          }
          if (details.phone) {
            setError('phone', { type: 'manual', message: details.phone });
          }
        } else {
          showErrorToaster(message);
        }
      },
    });
  };

  const handleSexChange = useCallback((sex: UserSexEnumType) => {
    sexRef.current = sex;
  }, []);

  return {
    register,
    control,
    errors,
    avatar,
    initialSex: sessionInfo.sex,
    onSubmit: handleSubmit(submitHandler),
    onSexChange: handleSexChange,
    onAvatarChange: setAvatar,
  };
}
