import { v4 } from 'uuid';

export function getUuid(): string {
  return v4();
}
export function countToString(count: number, one: string, couple: string, many: string): string {
  const preLastDigit = Number(count) % 100;
  if (preLastDigit >= 5 && preLastDigit <= 20) return many;
  switch (preLastDigit % 10) {
    case 1:
      return one;
    case 2:
    case 3:
    case 4:
      return couple;
    default:
      return many;
  }
}
export function countToStringWithCounter(
  count: number,
  one: string,
  couple: string,
  many: string,
  zero?: string,
): string {
  return `${zero && count === 0 ? zero : count} ${countToString(count, one, couple, many)}`;
}

export function getNormalizedPriceValue(price?: number) {
  if (!price) {
    return '';
  }
  return Intl.NumberFormat('ru', { maximumFractionDigits: 2 }).format(price);
}

export function getObjectValue<T, P extends keyof T>(
  object: T | null | undefined,
  key: P,
  options: {
    emptyValue?: string;
    emptyObject?: string;
    format?: (value: T[P]) => string | JSX.Element;
  } = {},
): string | JSX.Element | T[P] {
  const { emptyValue = '-', emptyObject = '', format } = options;
  if (!object) {
    return emptyObject;
  }
  const val = object[key];
  if (val == null || typeof val === 'undefined') {
    return emptyValue;
  }
  if (format) {
    return format(val);
  }
  return val;
}

export function getValidatedValue<T>(
  value: T,
  options: {
    emptyValue?: string;
    format?: (value: Required<T>) => string | JSX.Element;
  } = {},
): string | JSX.Element | T {
  const { emptyValue = '', format } = options;
  if (value == null || typeof value === 'undefined') {
    return emptyValue;
  }
  if (format) {
    return format(value as Required<T>);
  }
  return value;
}

export function getSelectableOptionValueProperty<T extends SelectableOptionType>(
  key: AffordableSelectorKey<T>,
  selectedValue: Unset<SelectableValueType<T>>,
) {
  return selectedValue
    ? typeof selectedValue === 'string' || typeof selectedValue === 'number'
      ? selectedValue
      : selectedValue[key]
    : undefined;
}

export function getSelectableOptionLabelProperty<T extends SelectableOptionType>(
  key: AffordableSelectorKey<T>,
  option: T,
) {
  return option[key] as string | number;
}
