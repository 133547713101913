import clsx from 'clsx';
import {
  ButtonHTMLAttributes,
  DetailedHTMLProps,
  PropsWithChildren,
  forwardRef,
  memo,
} from 'react';

import styles from './button.module.scss';

type ButtonColorType =
  | 'main'
  | 'main-light'
  | 'dark'
  | 'gray'
  | 'white'
  | 'unset'
  | 'error'
  | 'disabled';
type ButtonTextType = 'medium' | 'unset';
type ButtonHeightType = 'default' | 'medium' | 'small' | 'thin' | 'unset';
type ButtonRadiusType = 'default' | 'small' | 'full' | 'top-default';
type ButtonPaddingType = 'default' | 'small' | 'unset';
type ButtonContentType = 'default' | 'center' | 'between';
type ButtonWidthType = 'full' | 'content';
type ButtonFontType = 'bold' | 'default';

export interface IButtonProps
  extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  buttonType?: ButtonColorType;
  text?: ButtonTextType;
  padding?: ButtonPaddingType;
  contentType?: ButtonContentType;
  radius?: ButtonRadiusType;
  height?: ButtonHeightType;
  font?: ButtonFontType;
  width?: ButtonWidthType;
}
export const Button = memo(
  forwardRef<HTMLButtonElement, PropsWithChildren<IButtonProps>>(function Button(
    {
      type = 'button',
      text = 'unset',
      buttonType = 'main',
      height = 'default',
      radius = 'default',
      font = 'bold',
      width = 'full',
      padding = 'default',
      contentType = 'default',
      className,
      ...rest
    },
    ref,
  ) {
    return (
      <button
        type={type}
        className={clsx(
          styles.main,
          buttonType !== 'unset' &&
            (rest.disabled ? styles['type-disabled'] : styles[`type-${buttonType}`]),
          text !== 'unset' && styles[`text-${text}`],
          height !== 'unset' && styles[`height-${height}`],
          styles[`radius-${radius}`],
          styles[`text-${font}`],
          contentType !== 'default' && styles[`content-type-${contentType}`],
          padding !== 'unset' && styles[`padding-${padding}`],
          width !== 'content' && styles[`width-${width}`],
          className,
        )}
        ref={ref}
        {...rest}
      />
    );
  }),
);
