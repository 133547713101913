import { FC } from 'react';

import { AbonementIcon } from 'entities/abonement';

import { DateUtil } from 'shared/helpers/date';
import { IconArrowRight } from 'shared/icons';
import { Button } from 'shared/ui/controls';
import { Content, Text } from 'shared/ui/layout';

import {
  IUseUserCurrentAbonementArgs,
  useUserCurrentAbonement,
} from './user-current-abonement.hook';

import styles from './user-current-abonement.module.scss';

interface IUserCurrentAbonementProps extends IUseUserCurrentAbonementArgs {
  onSignup?: () => void;
}

export const UserCurrentAbonement: FC<IUserCurrentAbonementProps> = ({ onSignup, ...args }) => {
  const { type, createdAt, activatedAt, expiredAt, expiredText, statusText } =
    useUserCurrentAbonement(args);

  return (
    <>
      <Content mobilePadding='small'>
        <div className={styles['icon-wrapper']}>
          <AbonementIcon type={type} className={styles.icon} />
        </div>
        <Text type='supertitle' className='mb-2'>
          Ваш абонемент
        </Text>
        <Text type='title' className={styles.description}>
          {statusText}
        </Text>
        {onSignup && (
          <Button
            className={styles.action}
            height='thin'
            contentType='center'
            radius='small'
            padding='small'
            buttonType='gray'
            font='default'
            width='content'
            text='medium'
            onClick={onSignup}
          >
            Записаться
            <img src={IconArrowRight} alt='' />
          </Button>
        )}
      </Content>
      <Content type='modal-form' className='flex justify-between mb-3 gap-3'>
        <div className='flex-1'>
          <Text type='block-subtitle'>{DateUtil.formatDate(createdAt, 'DD MMM')}</Text>
          <Text type='small-text'>дата покупки</Text>
        </div>
        <div className='flex-1'>
          {activatedAt && (
            <>
              <Text type='block-subtitle'>{DateUtil.formatDate(activatedAt, 'DD MMM')}</Text>
              <Text type='small-text'>дата активации</Text>
            </>
          )}
        </div>
        <div className='flex-1'>
          {expiredAt && (
            <>
              <Text type='block-subtitle'>{expiredText}</Text>
              <Text type='small-text'>срок действия</Text>
              <Text type='small-text' text='opacity-30'>
                до {DateUtil.formatDate(expiredAt)}
              </Text>
            </>
          )}
        </div>
      </Content>
    </>
  );
};
