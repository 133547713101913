import dayjs from 'dayjs';
import 'dayjs/locale/ru';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekday from 'dayjs/plugin/weekday';

dayjs.locale('ru');
dayjs.extend(weekday);
dayjs.extend(customParseFormat);

export type DateType = dayjs.Dayjs;
export type DateInputType = dayjs.ConfigType | number[];
export type DateFormatType = dayjs.OptionType;

export class DateUtil {
  static getDateObj(date?: DateInputType, format?: DateFormatType): DateType {
    let actualDate: DateInputType;
    let actualFormat = format;

    if (Array.isArray(date)) {
      actualDate = date.join(' ');
      if (!format) {
        actualFormat = ['YYYY', 'M', 'D', 'H', 'm', 's'].slice(0, date.length).join(' ');
      }
    } else {
      actualDate = date;
    }
    if (date && typeof date === 'string' && !format) {
      actualFormat = 'DD.MM.YYYY';
    }
    return dayjs(actualDate, actualFormat);
  }

  static stringToDateObj(stringDate: string, format?: DateFormatType): DateType {
    return DateUtil.getDateObj(stringDate, format);
  }

  static dateObjToString(momentDate?: DateType, format = 'DD.MM.YYYY'): string {
    if (momentDate) {
      return momentDate.format(format);
    }
    return '';
  }

  static dateToString(date: Date | null, format = 'DD.MM.YYYY') {
    if (date) {
      return this.dateObjToString(this.getDateObj(date), format);
    }
    return undefined;
  }

  static formatDate(javaLocalDate: number[], format?: string): string {
    if (javaLocalDate) {
      if (Array.isArray(javaLocalDate)) {
        const time = [...javaLocalDate];
        if (javaLocalDate.length > 6) {
          time[6] = 0;
        }
        return DateUtil.getDateObj(time).format(format || 'DD.MM.YYYY');
      }
    }
    return '';
  }

  static formatNumberHour(hour?: number) {
    if (!hour) {
      return '';
    }
    return `${hour < 10 ? `0${hour}` : hour}:00`;
  }

  static dateObjAndTimeToString(args: {
    date?: DateType;
    time?: number;
    format?: string;
    join?: string;
    empty?: string;
    timeFirst?: boolean;
  }) {
    const { date, time, format = 'D MMMM', join = ', ', empty = '\xa0', timeFirst = false } = args;
    if (!date || typeof time === 'undefined') {
      return empty;
    }
    const dateText = DateUtil.dateObjToString(date, format);
    const timeText = DateUtil.formatNumberHour(time);
    if (timeFirst) {
      return `${timeText}${join}${dateText}`;
    }
    return `${dateText}${join}${timeText}`;
  }
  static localDateAndTimeToString(args: {
    date?: number[];
    time?: number;
    format?: string;
    join?: string;
    empty?: string;
    timeFirst?: boolean;
  }) {
    const { date, ...rest } = args;
    return this.dateObjAndTimeToString({ date: DateUtil.getDateObj(date), ...rest });
  }
}
