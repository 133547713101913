import clsx from 'clsx';
import { FC } from 'react';

import { SimpleUserElement } from 'entities/user';

import { DateUtil } from 'shared/helpers/date';

import { IUseCoachDaySlotsArgs, useCoachDaySlots } from './coach-day-slots.hook';

import styles from './coach-day-slots.module.scss';

export interface ICoachDaySlotsContainerProps extends IUseCoachDaySlotsArgs {
  className?: string;
}
export const CoachDaySlots: FC<ICoachDaySlotsContainerProps> = ({ className, ...args }) => {
  const { slots, onClick } = useCoachDaySlots(args);

  return (
    <div className={clsx(styles.container, className)}>
      <table className={styles.slots}>
        <tbody>
          {slots.map((slot) => (
            <tr
              key={slot.time}
              className={clsx(styles.slot, slot.expired && styles.slot_expired)}
              onClick={() => onClick(slot.time)}
            >
              <td>{DateUtil.formatNumberHour(slot.time)}</td>
              <td>
                <div className={styles.clients}>
                  {slot.clients.map((client) => (
                    <SimpleUserElement key={client.id} data={client} dotOverflow={false} />
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
