const getEnvVar = (key: string) => {
  if (process.env[key] === undefined) {
    throw new Error(`Env variable ${key} is required`);
  }
  return process.env[key] || '';
};

const NODE_ENV = getEnvVar('NODE_ENV');

export const MAIN_DOMAIN = window.location.host;

export const API_URL = '/api';

export const BASE_URL = `https://${MAIN_DOMAIN}${API_URL}`;

export const DEFAULT_ADDRESS = '1-я Тверская-Ямская, д. 29, стр. 1';

export const YANDEX_MAP_ADDRESS_URL = 'https://yandex.ru/maps/-/CDuOMZ86';

export const MAIN_SITE_URL = 'https://stretching-space.ru/';

export const isDevEnv = NODE_ENV === 'development';

export const isProdEnv = NODE_ENV === 'production';

export const CAPTCHA_KEY = getEnvVar('REACT_APP_CAPTCHA_KEY');